<owc-header>
  <owc-header-row elevation variant="top-primary" class="header-first-row">
    <owc-hyperlink
      [href]="'https://diagnostics.roche.com/'"
      title="Roche Diagnostics"
      target="_blank"
    >
      Roche Diagnostics
    </owc-hyperlink>

    <span class="flex-spacer"></span>

    <div class="settings">
      <div *ngIf="profileData" class="user" title="User Settings">
        <div id="menu-trigger">
          <owc-icon name="user"></owc-icon>
          <div>{{ profileData.name }}</div>
        </div>

        <owc-menu anchor="menu-trigger" position="bottom" class="profile-menu">
          <div class="profile-menu-top">
            <owc-avatar
              style="
                background-color: var(
                  --one-color-interaction-default-brand-base
                );
                color: var(--one-color-interaction-default-base);
              "
            >
              <owc-typography
                variant="title5"
                class="typography user-initial"
                >{{ profileData.initials }}</owc-typography
              >
            </owc-avatar>
            <div class="user-details">
              <owc-typography variant="subtitle2" class="typography">
                {{ profileData.name }}
              </owc-typography>
              <owc-typography
                variant="body2"
                class="typography user-email"
                [title]="profileData.email"
              >
                {{ profileData.email }}
              </owc-typography>
            </div>
          </div>
          <owc-divider></owc-divider>
          <button class="profile-menu-bottom" (click)="logout()">
            <owc-icon name="standby"></owc-icon>
            <owc-typography variant="body1"> Logout </owc-typography>
          </button>
          <owc-divider></owc-divider>
        </owc-menu>
      </div>

      <div title="Country/Language">
        <owc-icon name="globe"></owc-icon>
        <div>Global/EN</div>
      </div>
    </div>
  </owc-header-row>

  <owc-header-row
    seperator
    elevated
    [variant]="'primary'"
    class="header-second-row"
  >
    <div>
      <owc-hyperlink [href]="'/'" title="App Enabler" target="_blank">
        <img
          alt="eService Logo"
          class="app-logo"
          src="assets/appenabler_logo.svg"
        />
      </owc-hyperlink>
      <div class="app-title">App Enabler</div>
      <owc-tabs [value]="'menu'">
        <owc-tab value="menu">
          <span>Menu</span>
        </owc-tab>
      </owc-tabs>
    </div>

    <div>
      <owc-hyperlink
        [href]="'https://diagnostics.roche.com/'"
        title="Roche"
        target="_blank"
      >
        <owc-logo name="roche_blue" size="mini" title="Roche"></owc-logo>
      </owc-hyperlink>
    </div>
  </owc-header-row>
</owc-header>
