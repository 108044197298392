import { p as proxyCustomElement, H, h, c as Host } from './p-25bf8c52.js';
import './p-604842db.js';
import { b as accessibility } from './p-756e7dc5.js';
import './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
const headerCss = ".sc-owc-header-h{width:100%}";
const OwcHeaderStyle0 = headerCss;
const Header = /*@__PURE__*/proxyCustomElement(class Header extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    return h(Host, Object.assign({
      key: 'b0ff058d1595586eba2c4307ac996acb95a26c6f',
      class: hostClasses
    }, accessibility({
      role: 'banner'
    })), h("slot", {
      key: '04fa3c96a541aeba4147edef5d7001d2e79eac4a'
    }));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcHeaderStyle0;
  }
}, [6, "owc-header"]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-header"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-header":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Header);
        }
        break;
    }
  });
}
const OwcHeader = Header;
const defineCustomElement = defineCustomElement$1;
export { OwcHeader, defineCustomElement };