import * as i0 from '@angular/core';
import { forwardRef, Directive, HostListener, EventEmitter, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { __decorate } from 'tslib';
import { OWC_GLOBAL_KEY, RUNTIME_FWK_GLOBAL_KEY } from '@one/web-components';
import { defineCustomElement as defineCustomElement$1 } from '@one/web-components/components/owc-avatar.js';
import { defineCustomElement as defineCustomElement$2 } from '@one/web-components/components/owc-badge.js';
import { defineCustomElement as defineCustomElement$3 } from '@one/web-components/components/owc-button.js';
import { defineCustomElement as defineCustomElement$4 } from '@one/web-components/components/owc-chip.js';
import { defineCustomElement as defineCustomElement$5 } from '@one/web-components/components/owc-fab-button.js';
import { defineCustomElement as defineCustomElement$6 } from '@one/web-components/components/owc-file-uploader-item.js';
import { defineCustomElement as defineCustomElement$7 } from '@one/web-components/components/owc-icon.js';
import { defineCustomElement as defineCustomElement$8 } from '@one/web-components/components/owc-icon-button.js';
import { defineCustomElement as defineCustomElement$9 } from '@one/web-components/components/owc-indicator-badge.js';
import { defineCustomElement as defineCustomElement$a } from '@one/web-components/components/owc-logo.js';
import { defineCustomElement as defineCustomElement$b } from '@one/web-components/components/owc-progress-bar.js';
import { defineCustomElement as defineCustomElement$c } from '@one/web-components/components/owc-progress-spinner.js';
import { defineCustomElement as defineCustomElement$d } from '@one/web-components/components/owc-skeleton-loader.js';
import { defineCustomElement as defineCustomElement$e } from '@one/web-components/components/owc-tag.js';
import { defineCustomElement as defineCustomElement$f } from '@one/web-components/components/owc-toggle-button.js';
import { defineCustomElement as defineCustomElement$g } from '@one/web-components/components/owc-toggle-button-group.js';
import { defineCustomElement as defineCustomElement$h } from '@one/web-components/components/owc-typography.js';
const _c0 = ["*"];
class OwcToggleButtonAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcToggleButtonAccessor.ɵfac = function OwcToggleButtonAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcToggleButtonAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcToggleButtonAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcToggleButtonAccessor,
  selectors: [["owc-toggle-button"]],
  hostBindings: function OwcToggleButtonAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcToggleButtonAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcToggleButtonAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcToggleButtonAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButtonAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-toggle-button',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcToggleButtonAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcToggleButtonGroupAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcToggleButtonGroupAccessor.ɵfac = function OwcToggleButtonGroupAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcToggleButtonGroupAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcToggleButtonGroupAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcToggleButtonGroupAccessor,
  selectors: [["owc-toggle-button-group"]],
  hostBindings: function OwcToggleButtonGroupAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcToggleButtonGroupAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcToggleButtonGroupAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcToggleButtonGroupAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButtonGroupAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-toggle-button-group',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcToggleButtonGroupAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
const DIRECTIVES = [OwcToggleButtonAccessor, OwcToggleButtonGroupAccessor];

/* eslint-disable */
const fixNestedFunctionCalls = (val, self) => {
  if (val instanceof Element || val instanceof Document) {
    return val;
  } else if (Array.isArray(val)) {
    return val.map(v => fixNestedFunctionCalls(v, self));
  } else if (val === null || val === undefined) {
    return val;
  } else if (val instanceof Date) {
    return new Date(val.getTime());
  } else if (typeof val === 'object') {
    const fixedObj = {};
    for (const key of Object.keys(val)) {
      fixedObj[key] = fixNestedFunctionCalls(val[key], self);
    }
    return fixedObj;
  } else if (typeof val === 'function') {
    return (...args) => self.z.runTask(() => val(...args));
  } else {
    return val;
  }
};
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = fixNestedFunctionCalls(val, this));
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  //   events.forEach(eventName => (instance[eventName] = fromEvent(el, eventName)));
  events.forEach(eventName => instance[eventName] = new EventEmitter());
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (typeof window !== 'undefined' && OWC_GLOBAL_KEY in window && !(RUNTIME_FWK_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
      Object.defineProperty(window[OWC_GLOBAL_KEY], RUNTIME_FWK_GLOBAL_KEY, {
        configurable: true,
        enumerable: true,
        writable: true,
        value: 'angular'
      });
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
defineCustomElement$1();
let OwcAvatar = class OwcAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcAvatar.ɵfac = function OwcAvatar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcAvatar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcAvatar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcAvatar,
  selectors: [["owc-avatar"]],
  inputs: {
    disabled: "disabled",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcAvatar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcAvatar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1,
  inputs: ['disabled', 'size']
})], OwcAvatar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcAvatar, [{
    type: Component,
    args: [{
      selector: 'owc-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'size']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$2();
let OwcBadge = class OwcBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcBadge.ɵfac = function OwcBadge_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBadge.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBadge,
  selectors: [["owc-badge"]],
  inputs: {
    disabled: "disabled",
    hostRole: "hostRole",
    size: "size",
    status: "status"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBadge_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBadge = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$2,
  inputs: ['disabled', 'hostRole', 'size', 'status']
})], OwcBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBadge, [{
    type: Component,
    args: [{
      selector: 'owc-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'hostRole', 'size', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$3();
let OwcButton = class OwcButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcButton.ɵfac = function OwcButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcButton,
  selectors: [["owc-button"]],
  inputs: {
    disableRipple: "disableRipple",
    disabled: "disabled",
    elevated: "elevated",
    nativeButtonProps: "nativeButtonProps",
    rounded: "rounded",
    size: "size",
    status: "status",
    type: "type",
    usage: "usage",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$3,
  inputs: ['disableRipple', 'disabled', 'elevated', 'nativeButtonProps', 'rounded', 'size', 'status', 'type', 'usage', 'variant']
})], OwcButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcButton, [{
    type: Component,
    args: [{
      selector: 'owc-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableRipple', 'disabled', 'elevated', 'nativeButtonProps', 'rounded', 'size', 'status', 'type', 'usage', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$4();
let OwcChip = class OwcChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['remove']);
  }
};
OwcChip.ɵfac = function OwcChip_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcChip.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcChip,
  selectors: [["owc-chip"]],
  inputs: {
    closeIconProps: "closeIconProps",
    disableRipple: "disableRipple",
    disabled: "disabled",
    dragging: "dragging",
    selected: "selected",
    selectedIconProps: "selectedIconProps",
    showClose: "showClose",
    size: "size",
    variant: "variant"
  },
  outputs: {
    remove: "remove"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcChip_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcChip = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$4,
  inputs: ['closeIconProps', 'disableRipple', 'disabled', 'dragging', 'selected', 'selectedIconProps', 'showClose', 'size', 'variant']
})], OwcChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcChip, [{
    type: Component,
    args: [{
      selector: 'owc-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['closeIconProps', 'disableRipple', 'disabled', 'dragging', 'selected', 'selectedIconProps', 'showClose', 'size', 'variant'],
      outputs: ['remove']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$5();
let OwcFabButton = class OwcFabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcFabButton.ɵfac = function OwcFabButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcFabButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcFabButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcFabButton,
  selectors: [["owc-fab-button"]],
  inputs: {
    disableRipple: "disableRipple",
    disabled: "disabled",
    nativeButtonProps: "nativeButtonProps",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcFabButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcFabButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['disableRipple', 'disabled', 'nativeButtonProps', 'size']
})], OwcFabButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcFabButton, [{
    type: Component,
    args: [{
      selector: 'owc-fab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableRipple', 'disabled', 'nativeButtonProps', 'size']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$6();
let OwcFileUploaderItem = class OwcFileUploaderItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dismiss']);
  }
};
OwcFileUploaderItem.ɵfac = function OwcFileUploaderItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcFileUploaderItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcFileUploaderItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcFileUploaderItem,
  selectors: [["owc-file-uploader-item"]],
  inputs: {
    device: "device",
    disabled: "disabled",
    dismissIconProps: "dismissIconProps",
    prefixIconProps: "prefixIconProps",
    progressBarProps: "progressBarProps",
    status: "status",
    value: "value"
  },
  outputs: {
    dismiss: "dismiss"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcFileUploaderItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcFileUploaderItem = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: ['device', 'disabled', 'dismissIconProps', 'prefixIconProps', 'progressBarProps', 'status', 'value']
})], OwcFileUploaderItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcFileUploaderItem, [{
    type: Component,
    args: [{
      selector: 'owc-file-uploader-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['device', 'disabled', 'dismissIconProps', 'prefixIconProps', 'progressBarProps', 'status', 'value'],
      outputs: ['dismiss']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$7();
let OwcIcon = class OwcIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIcon.ɵfac = function OwcIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIcon,
  selectors: [["owc-icon"]],
  inputs: {
    alt: "alt",
    family: "family",
    name: "name",
    status: "status"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIcon = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['alt', 'family', 'name', 'status']
})], OwcIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIcon, [{
    type: Component,
    args: [{
      selector: 'owc-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alt', 'family', 'name', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$8();
let OwcIconButton = class OwcIconButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIconButton.ɵfac = function OwcIconButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcIconButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIconButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIconButton,
  selectors: [["owc-icon-button"]],
  inputs: {
    active: "active",
    alt: "alt",
    alternate: "alternate",
    disableRipple: "disableRipple",
    disabled: "disabled",
    elevated: "elevated",
    family: "family",
    icon: "icon",
    nativeButtonProps: "nativeButtonProps",
    size: "size",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIconButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIconButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8,
  inputs: ['active', 'alt', 'alternate', 'disableRipple', 'disabled', 'elevated', 'family', 'icon', 'nativeButtonProps', 'size', 'variant']
})], OwcIconButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIconButton, [{
    type: Component,
    args: [{
      selector: 'owc-icon-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['active', 'alt', 'alternate', 'disableRipple', 'disabled', 'elevated', 'family', 'icon', 'nativeButtonProps', 'size', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$9();
let OwcIndicatorBadge = class OwcIndicatorBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIndicatorBadge.ɵfac = function OwcIndicatorBadge_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcIndicatorBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIndicatorBadge.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIndicatorBadge,
  selectors: [["owc-indicator-badge"]],
  inputs: {
    badgeProps: "badgeProps",
    content: "content",
    disabled: "disabled",
    horizontalPlacement: "horizontalPlacement",
    overlap: "overlap",
    size: "size",
    status: "status",
    verticalPlacement: "verticalPlacement"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIndicatorBadge_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIndicatorBadge = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9,
  inputs: ['badgeProps', 'content', 'disabled', 'horizontalPlacement', 'overlap', 'size', 'status', 'verticalPlacement']
})], OwcIndicatorBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIndicatorBadge, [{
    type: Component,
    args: [{
      selector: 'owc-indicator-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['badgeProps', 'content', 'disabled', 'horizontalPlacement', 'overlap', 'size', 'status', 'verticalPlacement']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$a();
let OwcLogo = class OwcLogo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcLogo.ɵfac = function OwcLogo_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcLogo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcLogo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcLogo,
  selectors: [["owc-logo"]],
  inputs: {
    alt: "alt",
    name: "name",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcLogo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcLogo = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$a,
  inputs: ['alt', 'name', 'size']
})], OwcLogo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcLogo, [{
    type: Component,
    args: [{
      selector: 'owc-logo',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alt', 'name', 'size']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$b();
let OwcProgressBar = class OwcProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcProgressBar.ɵfac = function OwcProgressBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcProgressBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcProgressBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcProgressBar,
  selectors: [["owc-progress-bar"]],
  inputs: {
    caption: "caption",
    disabled: "disabled",
    hostId: "hostId",
    mode: "mode",
    status: "status",
    value: "value",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcProgressBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcProgressBar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$b,
  inputs: ['caption', 'disabled', 'hostId', 'mode', 'status', 'value', 'variant']
})], OwcProgressBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcProgressBar, [{
    type: Component,
    args: [{
      selector: 'owc-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['caption', 'disabled', 'hostId', 'mode', 'status', 'value', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$c();
let OwcProgressSpinner = class OwcProgressSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcProgressSpinner.ɵfac = function OwcProgressSpinner_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcProgressSpinner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcProgressSpinner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcProgressSpinner,
  selectors: [["owc-progress-spinner"]],
  inputs: {
    mode: "mode",
    size: "size",
    value: "value",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcProgressSpinner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcProgressSpinner = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$c,
  inputs: ['mode', 'size', 'value', 'variant']
})], OwcProgressSpinner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcProgressSpinner, [{
    type: Component,
    args: [{
      selector: 'owc-progress-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['mode', 'size', 'value', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$d();
let OwcSkeletonLoader = class OwcSkeletonLoader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcSkeletonLoader.ɵfac = function OwcSkeletonLoader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcSkeletonLoader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSkeletonLoader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSkeletonLoader,
  selectors: [["owc-skeleton-loader"]],
  inputs: {
    alternate: "alternate",
    animation: "animation",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSkeletonLoader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSkeletonLoader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$d,
  inputs: ['alternate', 'animation', 'variant']
})], OwcSkeletonLoader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSkeletonLoader, [{
    type: Component,
    args: [{
      selector: 'owc-skeleton-loader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alternate', 'animation', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$e();
let OwcTag = class OwcTag {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTag.ɵfac = function OwcTag_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTag)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTag.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTag,
  selectors: [["owc-tag"]],
  inputs: {
    disabled: "disabled",
    outlined: "outlined",
    rounded: "rounded",
    status: "status"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTag_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTag = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$e,
  inputs: ['disabled', 'outlined', 'rounded', 'status']
})], OwcTag);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTag, [{
    type: Component,
    args: [{
      selector: 'owc-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'outlined', 'rounded', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$f();
let OwcToggleButton = class OwcToggleButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcToggleButton.ɵfac = function OwcToggleButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcToggleButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcToggleButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcToggleButton,
  selectors: [["owc-toggle-button"]],
  inputs: {
    disableRipple: "disableRipple",
    disabled: "disabled",
    groupDisabled: "groupDisabled",
    hostId: "hostId",
    nativeButtonProps: "nativeButtonProps",
    selected: "selected",
    value: "value"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcToggleButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcToggleButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$f,
  inputs: ['disableRipple', 'disabled', 'groupDisabled', 'hostId', 'nativeButtonProps', 'selected', 'value']
})], OwcToggleButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButton, [{
    type: Component,
    args: [{
      selector: 'owc-toggle-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableRipple', 'disabled', 'groupDisabled', 'hostId', 'nativeButtonProps', 'selected', 'value'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$g();
let OwcToggleButtonGroup = class OwcToggleButtonGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcToggleButtonGroup.ɵfac = function OwcToggleButtonGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcToggleButtonGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcToggleButtonGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcToggleButtonGroup,
  selectors: [["owc-toggle-button-group"]],
  inputs: {
    controlled: "controlled",
    disabled: "disabled",
    elevated: "elevated",
    exclusive: "exclusive",
    rounded: "rounded",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcToggleButtonGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcToggleButtonGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$g,
  inputs: ['controlled', 'disabled', 'elevated', 'exclusive', 'rounded', 'value']
})], OwcToggleButtonGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButtonGroup, [{
    type: Component,
    args: [{
      selector: 'owc-toggle-button-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['controlled', 'disabled', 'elevated', 'exclusive', 'rounded', 'value'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$h();
let OwcTypography = class OwcTypography {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTypography.ɵfac = function OwcTypography_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTypography)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTypography.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTypography,
  selectors: [["owc-typography"]],
  inputs: {
    device: "device",
    element: "element",
    mode: "mode",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTypography_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTypography = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$h,
  inputs: ['device', 'element', 'mode', 'variant']
})], OwcTypography);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTypography, [{
    type: Component,
    args: [{
      selector: 'owc-typography',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['device', 'element', 'mode', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const COMPONENTS = [OwcAvatar, OwcBadge, OwcButton, OwcChip, OwcFabButton, OwcFileUploaderItem, OwcIcon, OwcIconButton, OwcIndicatorBadge, OwcLogo, OwcProgressBar, OwcProgressSpinner, OwcSkeletonLoader, OwcTag, OwcToggleButton, OwcToggleButtonGroup, OwcTypography];
class ButtonsAndIndicatorsModule {}
ButtonsAndIndicatorsModule.ɵfac = function ButtonsAndIndicatorsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ButtonsAndIndicatorsModule)();
};
ButtonsAndIndicatorsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ButtonsAndIndicatorsModule
});
ButtonsAndIndicatorsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonsAndIndicatorsModule, [{
    type: NgModule,
    args: [{
      declarations: [...COMPONENTS, ...DIRECTIVES],
      imports: [],
      exports: [...COMPONENTS, ...DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonsAndIndicatorsModule, DIRECTIVES, OwcAvatar, OwcBadge, OwcButton, OwcChip, OwcFabButton, OwcFileUploaderItem, OwcIcon, OwcIconButton, OwcIndicatorBadge, OwcLogo, OwcProgressBar, OwcProgressSpinner, OwcSkeletonLoader, OwcTag, OwcToggleButton, OwcToggleButtonAccessor, OwcToggleButtonGroup, OwcToggleButtonGroupAccessor, OwcTypography };
