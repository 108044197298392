import { p as proxyCustomElement, H, h, c as Host } from './p-25bf8c52.js';
import { a as ComponentUtils, i as isChildMutated } from './p-cf58d171.js';
import './p-604842db.js';
import { a as accessibilityAttributes, b as accessibility } from './p-756e7dc5.js';
import './p-a00c815e.js';
import { O as OnElementMutation } from './p-f0ee7b1d.js';
const breadcrumbCss = ".sc-owc-breadcrumb-h{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:true;flex-wrap:true;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;outline:none;overflow-y:visible !important}.sc-owc-breadcrumb-h .owc-breadcrumb__breadcrumb-item-wrapper.sc-owc-breadcrumb{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:true;flex-wrap:true;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;outline:none}";
const OwcBreadcrumbStyle0 = breadcrumbCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const BREADCRUMB_ITEM = 'owc-breadcrumb-item';
const Breadcrumb = /*@__PURE__*/proxyCustomElement(class Breadcrumb extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.getItems = () => [...Array.from(this.hostElement.querySelectorAll(BREADCRUMB_ITEM))];
    this.propagateAttributesToItems = () => {
      this.getItems().forEach((el, index, childrenArray) => {
        var _a;
        el.icon = this.icon;
        el.iconFamily = this.iconFamily;
        el.parentDisabled = index === childrenArray.length - 1;
        el.hyperlinkProps = Object.assign(Object.assign({}, el.hyperlinkProps), {
          anchorProps: Object.assign({
            role: el.parentDisabled ? 'presentation' : undefined
          }, ((_a = el.hyperlinkProps) === null || _a === void 0 ? void 0 : _a.anchorProps) || {})
        });
        el.parentDisabled && el.setAttribute(...Object.entries(accessibilityAttributes({
          current: 'location'
        }))[0]);
      });
    };
    this.icon = 'chevron_right';
    this.iconFamily = 'outlined';
  }
  onIconChange() {
    this.propagateAttributesToItems();
  }
  onContentChange(records) {
    isChildMutated(records, this.hostElement) && this.propagateAttributesToItems();
  }
  componentDidLoad() {
    this.propagateAttributesToItems();
  }
  componentWillRender() {
    this.propagateAttributesToItems();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    const breadcrumbItemWrapperClasses = this.component.getChildBemClasses('breadcrumb-item-wrapper', hostClasses);
    return h(Host, Object.assign({
      key: '85ee55c508a855a4e5ed997d8810ab2d89abb79d',
      class: hostClasses,
      tabIndex: -1
    }, accessibility({
      role: 'navigation'
    })), h("div", Object.assign({
      key: 'c222042d9d2be17c9b2bed01f26e5a57ac166363',
      class: breadcrumbItemWrapperClasses
    }, accessibility({
      role: 'list'
    })), h("slot", {
      key: 'cc1d5a8583f5237ab38803cc50eb56820284bc44'
    })));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "icon": ["onIconChange"],
      "iconFamily": ["onIconChange"]
    };
  }
  static get style() {
    return OwcBreadcrumbStyle0;
  }
}, [6, "owc-breadcrumb", {
  "icon": [513],
  "iconFamily": [513, "icon-family"]
}, undefined, {
  "icon": ["onIconChange"],
  "iconFamily": ["onIconChange"]
}]);
__decorate([OnElementMutation({
  target: self => self.hostElement,
  attributes: true,
  childList: true
})], Breadcrumb.prototype, "onContentChange", null);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-breadcrumb"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-breadcrumb":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Breadcrumb);
        }
        break;
    }
  });
}
const OwcBreadcrumb = Breadcrumb;
const defineCustomElement = defineCustomElement$1;
export { OwcBreadcrumb, defineCustomElement };