import * as i0 from '@angular/core';
import { forwardRef, Directive, HostListener, EventEmitter, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { __decorate } from 'tslib';
import { OWC_GLOBAL_KEY, RUNTIME_FWK_GLOBAL_KEY } from '@one/web-components';
import { defineCustomElement as defineCustomElement$1 } from '@one/web-components/components/owc-breadcrumb.js';
import { defineCustomElement as defineCustomElement$2 } from '@one/web-components/components/owc-breadcrumb-item.js';
import { defineCustomElement as defineCustomElement$3 } from '@one/web-components/components/owc-dot.js';
import { defineCustomElement as defineCustomElement$4 } from '@one/web-components/components/owc-dots.js';
import { defineCustomElement as defineCustomElement$5 } from '@one/web-components/components/owc-flex-menu.js';
import { defineCustomElement as defineCustomElement$6 } from '@one/web-components/components/owc-header.js';
import { defineCustomElement as defineCustomElement$7 } from '@one/web-components/components/owc-header-row.js';
import { defineCustomElement as defineCustomElement$8 } from '@one/web-components/components/owc-hyperlink.js';
import { defineCustomElement as defineCustomElement$9 } from '@one/web-components/components/owc-menu.js';
import { defineCustomElement as defineCustomElement$a } from '@one/web-components/components/owc-pagination.js';
import { defineCustomElement as defineCustomElement$b } from '@one/web-components/components/owc-step.js';
import { defineCustomElement as defineCustomElement$c } from '@one/web-components/components/owc-stepper.js';
const _c0 = ["*"];
class OwcDotAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcDotAccessor.ɵfac = function OwcDotAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcDotAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcDotAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcDotAccessor,
  selectors: [["owc-dot"]],
  hostBindings: function OwcDotAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcDotAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcDotAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcDotAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDotAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-dot',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcDotAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcDotsAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcDotsAccessor.ɵfac = function OwcDotsAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcDotsAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcDotsAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcDotsAccessor,
  selectors: [["owc-dots"]],
  hostBindings: function OwcDotsAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcDotsAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcDotsAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcDotsAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDotsAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-dots',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcDotsAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcFlexMenuAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcFlexMenuAccessor.ɵfac = function OwcFlexMenuAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcFlexMenuAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcFlexMenuAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcFlexMenuAccessor,
  selectors: [["owc-flex-menu"]],
  hostBindings: function OwcFlexMenuAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcFlexMenuAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcFlexMenuAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcFlexMenuAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcFlexMenuAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-flex-menu',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcFlexMenuAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcMenuAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcMenuAccessor.ɵfac = function OwcMenuAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcMenuAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcMenuAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcMenuAccessor,
  selectors: [["owc-menu"]],
  hostBindings: function OwcMenuAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcMenuAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcMenuAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcMenuAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcMenuAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-menu',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcMenuAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcStepperAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('activeStep', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcStepperAccessor.ɵfac = function OwcStepperAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcStepperAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcStepperAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcStepperAccessor,
  selectors: [["owc-stepper"]],
  hostBindings: function OwcStepperAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("activeStepChange", function OwcStepperAccessor_activeStepChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcStepperAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcStepperAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcStepperAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-stepper',
      host: {
        '(activeStepChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcStepperAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
const DIRECTIVES = [OwcDotAccessor, OwcDotsAccessor, OwcFlexMenuAccessor, OwcMenuAccessor, OwcStepperAccessor];

/* eslint-disable */
const fixNestedFunctionCalls = (val, self) => {
  if (val instanceof Element || val instanceof Document) {
    return val;
  } else if (Array.isArray(val)) {
    return val.map(v => fixNestedFunctionCalls(v, self));
  } else if (val === null || val === undefined) {
    return val;
  } else if (val instanceof Date) {
    return new Date(val.getTime());
  } else if (typeof val === 'object') {
    const fixedObj = {};
    for (const key of Object.keys(val)) {
      fixedObj[key] = fixNestedFunctionCalls(val[key], self);
    }
    return fixedObj;
  } else if (typeof val === 'function') {
    return (...args) => self.z.runTask(() => val(...args));
  } else {
    return val;
  }
};
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = fixNestedFunctionCalls(val, this));
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  //   events.forEach(eventName => (instance[eventName] = fromEvent(el, eventName)));
  events.forEach(eventName => instance[eventName] = new EventEmitter());
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (typeof window !== 'undefined' && OWC_GLOBAL_KEY in window && !(RUNTIME_FWK_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
      Object.defineProperty(window[OWC_GLOBAL_KEY], RUNTIME_FWK_GLOBAL_KEY, {
        configurable: true,
        enumerable: true,
        writable: true,
        value: 'angular'
      });
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
defineCustomElement$1();
let OwcBreadcrumb = class OwcBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcBreadcrumb.ɵfac = function OwcBreadcrumb_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBreadcrumb.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBreadcrumb,
  selectors: [["owc-breadcrumb"]],
  inputs: {
    icon: "icon",
    iconFamily: "iconFamily"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBreadcrumb_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBreadcrumb = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1,
  inputs: ['icon', 'iconFamily']
})], OwcBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'owc-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['icon', 'iconFamily']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$2();
let OwcBreadcrumbItem = class OwcBreadcrumbItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcBreadcrumbItem.ɵfac = function OwcBreadcrumbItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcBreadcrumbItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBreadcrumbItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBreadcrumbItem,
  selectors: [["owc-breadcrumb-item"]],
  inputs: {
    disabled: "disabled",
    hyperlinkProps: "hyperlinkProps",
    icon: "icon",
    iconFamily: "iconFamily",
    parentDisabled: "parentDisabled",
    url: "url"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBreadcrumbItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBreadcrumbItem = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$2,
  inputs: ['disabled', 'hyperlinkProps', 'icon', 'iconFamily', 'parentDisabled', 'url']
})], OwcBreadcrumbItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBreadcrumbItem, [{
    type: Component,
    args: [{
      selector: 'owc-breadcrumb-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'hyperlinkProps', 'icon', 'iconFamily', 'parentDisabled', 'url']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$3();
let OwcDot = class OwcDot {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcDot.ɵfac = function OwcDot_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcDot)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDot.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDot,
  selectors: [["owc-dot"]],
  inputs: {
    controlled: "controlled",
    disableRipple: "disableRipple",
    disabled: "disabled",
    hostId: "hostId",
    parentDisabled: "parentDisabled",
    selected: "selected"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDot_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDot = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$3,
  inputs: ['controlled', 'disableRipple', 'disabled', 'hostId', 'parentDisabled', 'selected']
})], OwcDot);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDot, [{
    type: Component,
    args: [{
      selector: 'owc-dot',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['controlled', 'disableRipple', 'disabled', 'hostId', 'parentDisabled', 'selected'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$4();
let OwcDots = class OwcDots {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcDots.ɵfac = function OwcDots_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcDots)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDots.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDots,
  selectors: [["owc-dots"]],
  inputs: {
    controlled: "controlled",
    direction: "direction",
    disabled: "disabled",
    selected: "selected"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDots_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDots = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$4,
  inputs: ['controlled', 'direction', 'disabled', 'selected']
})], OwcDots);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDots, [{
    type: Component,
    args: [{
      selector: 'owc-dots',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['controlled', 'direction', 'disabled', 'selected'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$5();
let OwcFlexMenu = class OwcFlexMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clickOutside', 'visibleChange']);
  }
};
OwcFlexMenu.ɵfac = function OwcFlexMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcFlexMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcFlexMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcFlexMenu,
  selectors: [["owc-flex-menu"]],
  inputs: {
    anchor: "anchor",
    anchorWidth: "anchorWidth",
    cardProps: "cardProps",
    disableCircularFocus: "disableCircularFocus",
    disableFocusOnActive: "disableFocusOnActive",
    disableFocusOnInactive: "disableFocusOnInactive",
    hostId: "hostId",
    hostRole: "hostRole",
    noKeydownManager: "noKeydownManager",
    notFocusable: "notFocusable",
    offset: "offset",
    popoverProps: "popoverProps",
    position: "position",
    preventOverflow: "preventOverflow",
    strategy: "strategy",
    trigger: "trigger",
    visible: "visible"
  },
  outputs: {
    clickOutside: "clickOutside",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcFlexMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcFlexMenu = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['anchor', 'anchorWidth', 'cardProps', 'disableCircularFocus', 'disableFocusOnActive', 'disableFocusOnInactive', 'hostId', 'hostRole', 'noKeydownManager', 'notFocusable', 'offset', 'popoverProps', 'position', 'preventOverflow', 'strategy', 'trigger', 'visible']
})], OwcFlexMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcFlexMenu, [{
    type: Component,
    args: [{
      selector: 'owc-flex-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'anchorWidth', 'cardProps', 'disableCircularFocus', 'disableFocusOnActive', 'disableFocusOnInactive', 'hostId', 'hostRole', 'noKeydownManager', 'notFocusable', 'offset', 'popoverProps', 'position', 'preventOverflow', 'strategy', 'trigger', 'visible'],
      outputs: ['clickOutside', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$6();
let OwcHeader = class OwcHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcHeader.ɵfac = function OwcHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcHeader,
  selectors: [["owc-header"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: []
})], OwcHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcHeader, [{
    type: Component,
    args: [{
      selector: 'owc-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$7();
let OwcHeaderRow = class OwcHeaderRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcHeaderRow.ɵfac = function OwcHeaderRow_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcHeaderRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcHeaderRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcHeaderRow,
  selectors: [["owc-header-row"]],
  inputs: {
    elevated: "elevated",
    extended: "extended",
    separator: "separator",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcHeaderRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcHeaderRow = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['elevated', 'extended', 'separator', 'variant']
})], OwcHeaderRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcHeaderRow, [{
    type: Component,
    args: [{
      selector: 'owc-header-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['elevated', 'extended', 'separator', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$8();
let OwcHyperlink = class OwcHyperlink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcHyperlink.ɵfac = function OwcHyperlink_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcHyperlink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcHyperlink.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcHyperlink,
  selectors: [["owc-hyperlink"]],
  inputs: {
    anchorProps: "anchorProps",
    disabled: "disabled",
    href: "href",
    rel: "rel",
    size: "size",
    target: "target",
    usage: "usage"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcHyperlink_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcHyperlink = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8,
  inputs: ['anchorProps', 'disabled', 'href', 'rel', 'size', 'target', 'usage']
})], OwcHyperlink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcHyperlink, [{
    type: Component,
    args: [{
      selector: 'owc-hyperlink',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchorProps', 'disabled', 'href', 'rel', 'size', 'target', 'usage']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$9();
let OwcMenu = class OwcMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clickOutside', 'visibleChange']);
  }
};
OwcMenu.ɵfac = function OwcMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcMenu,
  selectors: [["owc-menu"]],
  inputs: {
    anchor: "anchor",
    anchorWidth: "anchorWidth",
    autoCloseOnSelection: "autoCloseOnSelection",
    cardProps: "cardProps",
    hostId: "hostId",
    limit: "limit",
    listProps: "listProps",
    noKeydownManager: "noKeydownManager",
    notFocusable: "notFocusable",
    offset: "offset",
    position: "position",
    preventOverflow: "preventOverflow",
    preventVisibilityChangeOnAnchorBlur: "preventVisibilityChangeOnAnchorBlur",
    strategy: "strategy",
    trigger: "trigger",
    usage: "usage",
    visible: "visible"
  },
  outputs: {
    clickOutside: "clickOutside",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcMenu = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9,
  inputs: ['anchor', 'anchorWidth', 'autoCloseOnSelection', 'cardProps', 'hostId', 'limit', 'listProps', 'noKeydownManager', 'notFocusable', 'offset', 'position', 'preventOverflow', 'preventVisibilityChangeOnAnchorBlur', 'strategy', 'trigger', 'usage', 'visible']
})], OwcMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcMenu, [{
    type: Component,
    args: [{
      selector: 'owc-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'anchorWidth', 'autoCloseOnSelection', 'cardProps', 'hostId', 'limit', 'listProps', 'noKeydownManager', 'notFocusable', 'offset', 'position', 'preventOverflow', 'preventVisibilityChangeOnAnchorBlur', 'strategy', 'trigger', 'usage', 'visible'],
      outputs: ['clickOutside', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$a();
let OwcPagination = class OwcPagination {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pageChange']);
  }
};
OwcPagination.ɵfac = function OwcPagination_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcPagination)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcPagination.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcPagination,
  selectors: [["owc-pagination"]],
  inputs: {
    controlled: "controlled",
    firstPageButtonProps: "firstPageButtonProps",
    hideFirstPageButton: "hideFirstPageButton",
    hideLastPageButton: "hideLastPageButton",
    hideNextPageButton: "hideNextPageButton",
    hidePageSize: "hidePageSize",
    hidePagesNumber: "hidePagesNumber",
    hidePreviousPageButton: "hidePreviousPageButton",
    inputProps: "inputProps",
    lastPageButtonProps: "lastPageButtonProps",
    nextPageButtonProps: "nextPageButtonProps",
    page: "page",
    pageSize: "pageSize",
    pageSizeOptions: "pageSizeOptions",
    previousPageButtonProps: "previousPageButtonProps",
    selectDropdownProps: "selectDropdownProps",
    showJumpToPage: "showJumpToPage",
    total: "total"
  },
  outputs: {
    pageChange: "pageChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcPagination_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcPagination = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$a,
  inputs: ['controlled', 'firstPageButtonProps', 'hideFirstPageButton', 'hideLastPageButton', 'hideNextPageButton', 'hidePageSize', 'hidePagesNumber', 'hidePreviousPageButton', 'inputProps', 'lastPageButtonProps', 'nextPageButtonProps', 'page', 'pageSize', 'pageSizeOptions', 'previousPageButtonProps', 'selectDropdownProps', 'showJumpToPage', 'total']
})], OwcPagination);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcPagination, [{
    type: Component,
    args: [{
      selector: 'owc-pagination',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['controlled', 'firstPageButtonProps', 'hideFirstPageButton', 'hideLastPageButton', 'hideNextPageButton', 'hidePageSize', 'hidePagesNumber', 'hidePreviousPageButton', 'inputProps', 'lastPageButtonProps', 'nextPageButtonProps', 'page', 'pageSize', 'pageSizeOptions', 'previousPageButtonProps', 'selectDropdownProps', 'showJumpToPage', 'total'],
      outputs: ['pageChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$b();
let OwcStep = class OwcStep {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcStep.ɵfac = function OwcStep_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcStep)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcStep.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcStep,
  selectors: [["owc-step"]],
  inputs: {
    direction: "direction",
    disabled: "disabled",
    dividerProps: "dividerProps",
    hostId: "hostId",
    linear: "linear",
    status: "status",
    step: "step"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcStep_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcStep = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$b,
  inputs: ['direction', 'disabled', 'dividerProps', 'hostId', 'linear', 'status', 'step']
})], OwcStep);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcStep, [{
    type: Component,
    args: [{
      selector: 'owc-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disabled', 'dividerProps', 'hostId', 'linear', 'status', 'step']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$c();
let OwcStepper = class OwcStepper {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['activeStepChange']);
  }
};
OwcStepper.ɵfac = function OwcStepper_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcStepper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcStepper.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcStepper,
  selectors: [["owc-stepper"]],
  inputs: {
    activeStep: "activeStep",
    controlled: "controlled",
    direction: "direction",
    linear: "linear"
  },
  outputs: {
    activeStepChange: "activeStepChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcStepper_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcStepper = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$c,
  inputs: ['activeStep', 'controlled', 'direction', 'linear']
})], OwcStepper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcStepper, [{
    type: Component,
    args: [{
      selector: 'owc-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['activeStep', 'controlled', 'direction', 'linear'],
      outputs: ['activeStepChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const COMPONENTS = [OwcBreadcrumb, OwcBreadcrumbItem, OwcDot, OwcDots, OwcFlexMenu, OwcHeader, OwcHeaderRow, OwcHyperlink, OwcMenu, OwcPagination, OwcStep, OwcStepper];
class NavigationModule {}
NavigationModule.ɵfac = function NavigationModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NavigationModule)();
};
NavigationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NavigationModule
});
NavigationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationModule, [{
    type: NgModule,
    args: [{
      declarations: [...COMPONENTS, ...DIRECTIVES],
      imports: [],
      exports: [...COMPONENTS, ...DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, NavigationModule, OwcBreadcrumb, OwcBreadcrumbItem, OwcDot, OwcDotAccessor, OwcDots, OwcDotsAccessor, OwcFlexMenu, OwcFlexMenuAccessor, OwcHeader, OwcHeaderRow, OwcHyperlink, OwcMenu, OwcMenuAccessor, OwcPagination, OwcStep, OwcStepper, OwcStepperAccessor };
