import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { View } from './shared/models/view.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly breakpointObserver = inject(BreakpointObserver);

  view = View;
  view$: Observable<View> = this.breakpointObserver
    .observe('(min-width: 600px)')
    .pipe(
      map((result: BreakpointState) => result.matches),
      map((desktop: boolean) => {
        if (!desktop) {
          return View.Mobile;
        } else {
          return View.DesktopLoggedIn;
        }
      }),
    );
}
