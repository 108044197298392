import { p as proxyCustomElement, H, h, c as Host } from './p-25bf8c52.js';
import { a as setTheme } from './p-f5827153.js';
const themeProviderCss = ".sc-owc-theme-provider-h{display:contents}";
const OwcThemeProviderStyle0 = themeProviderCss;
const ThemeProvider = /*@__PURE__*/proxyCustomElement(class ThemeProvider extends H {
  constructor() {
    super();
    this.__registerHost();
    this.updateTheme = () => setTheme(this.theme, this.customTheme, this.targetElement);
    this.theme = 'regular_navify_light';
    this.customTheme = {};
    this.targetElement = this.hostElement;
  }
  onThemeChange() {
    this.updateTheme();
  }
  componentDidLoad() {
    this.updateTheme();
  }
  render() {
    return h(Host, {
      key: '4ce2943ec547353290d7e8a423e290936c56f74f'
    }, h("slot", {
      key: '96a7b18c26a09f58ae327eec88c53a017346f797'
    }));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "theme": ["onThemeChange"],
      "customTheme": ["onThemeChange"],
      "targetElement": ["onThemeChange"]
    };
  }
  static get style() {
    return OwcThemeProviderStyle0;
  }
}, [6, "owc-theme-provider", {
  "theme": [513],
  "customTheme": [16],
  "targetElement": [16]
}, undefined, {
  "theme": ["onThemeChange"],
  "customTheme": ["onThemeChange"],
  "targetElement": ["onThemeChange"]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-theme-provider"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-theme-provider":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, ThemeProvider);
        }
        break;
    }
  });
}
const OwcThemeProvider = ThemeProvider;
const defineCustomElement = defineCustomElement$1;
export { OwcThemeProvider, defineCustomElement };