import { p as proxyCustomElement, H, h, c as Host } from './p-25bf8c52.js';
import './p-604842db.js';
import './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
const contactInfoCss = ".sc-owc-contact-info-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-weight:var(--one-text-font-weight-regular);width:calc(var(--one-spacing-size-7-xs) * 233);padding:var(--one-spacing-padding-2-xs)}.sc-owc-contact-info-h [slot=footer].sc-owc-contact-info{margin-top:var(--one-spacing-gap-4-xs)}.sc-owc-contact-info-h [slot=header].sc-owc-contact-info{font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-weight:var(--one-text-font-weight-medium)}.owc-contact-info--type-address.sc-owc-contact-info-h{border-top:var(--one-spacing-outline-semibold) solid var(--one-contact-info-color-address-border-top);background:var(--one-contact-info-color-address-background)}.owc-contact-info--type-address.sc-owc-contact-info-h [slot=avatar].sc-owc-contact-info{display:none}.owc-contact-info--type-address.sc-owc-contact-info-h [slot=header].sc-owc-contact-info{margin-bottom:var(--one-spacing-gap-5-xs)}.owc-contact-info--type-avatar.sc-owc-contact-info-h{width:calc(var(--one-spacing-size-7-xs) * 202)}.owc-contact-info--type-avatar.sc-owc-contact-info-h [slot=avatar].sc-owc-contact-info{margin-bottom:var(--one-spacing-gap-2-xs)}";
const OwcContactInfoStyle0 = contactInfoCss;
const ContactInfo = /*@__PURE__*/proxyCustomElement(class ContactInfo extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.type = 'address';
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      [`type-${this.type}`]: true
    });
    return h(Host, {
      key: '2886ad08a94959833a8a73a7e16943575fd080dd',
      class: hostClasses
    }, h("slot", {
      key: 'da9f1631d08fb86ab3b0a80ebd95e8a571b1b5f5',
      name: "avatar"
    }), h("slot", {
      key: 'fc270c86c3209cd1fcf3e8d76f94106e2c82f207',
      name: "header"
    }), h("slot", {
      key: '1dc489a261cc8b0ea75868d64ef6cef183d197e9'
    }), h("slot", {
      key: '2e10557b6e52dc5b2ff9511633979c913f157584',
      name: "footer"
    }));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcContactInfoStyle0;
  }
}, [6, "owc-contact-info", {
  "type": [513]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-contact-info"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-contact-info":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, ContactInfo);
        }
        break;
    }
  });
}
const OwcContactInfo = ContactInfo;
const defineCustomElement = defineCustomElement$1;
export { OwcContactInfo, defineCustomElement };