const accessibilityAttributes = attrs => {
  const returnValue = {};
  Object.entries(attrs || {}).forEach(([key, value]) => {
    const ariaKey = `aria-${key}`;
    if (typeof value === 'boolean') {
      returnValue[ariaKey] = value ? 'true' : 'false';
    } else if (Array.isArray(value)) {
      returnValue[ariaKey] = value.filter(Boolean).join(' ');
    } else if (typeof value === 'undefined') {
      returnValue[ariaKey] = '';
    } else {
      returnValue[ariaKey] = value;
    }
  });
  return returnValue;
};
const accessibility = input => {
  return Object.assign({
    role: input.role
  }, accessibilityAttributes(input.aria || {}));
};
const hideElementFromAccessibilityAPI = () => {
  // role="none" or role="presentation" violates the AXE accessibility rules
  return {
    'aria-hidden': 'true'
  };
};
const resetAccessibilityRole = () => ({
  role: 'presentation'
});
export { accessibilityAttributes as a, accessibility as b, hideElementFromAccessibilityAPI as h, resetAccessibilityRole as r };