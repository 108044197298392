import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppEnablerFirstComponent } from './features/app-enabler-first/app-enabler-first.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app-enabler',
    pathMatch: 'full',
  },
  {
    path: 'app-enabler',
    component: AppEnablerFirstComponent,
    data: { trackWithAnalytics: true },
  },
  {
    path: '**',
    redirectTo: '/app-enabler',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
