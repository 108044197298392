import { p as proxyCustomElement, H, h, c as Host } from './p-25bf8c52.js';
import { f as SCREEN_SIZE_GLOBAL_KEY, c as OWC_GLOBAL_KEY, S as SCREEN_GLOBAL_KEY, g as getDocument, i as isSSR, z as hasValue } from './p-604842db.js';
import { a as ComponentUtils, d as getScreenSize, e as getResponsiveAttributeValue } from './p-cf58d171.js';
import './p-a00c815e.js';
import { O as OnContextChange } from './p-4ae29297.js';
import { C as CTX_KEYS } from './p-e5c1eb43.js';
const gridItemCss = ".sc-owc-grid-item-h{display:-ms-flexbox;display:flex;-webkit-box-sizing:border-box;box-sizing:border-box;max-width:100%;width:100%;padding:var(--grid-item-padding, 0);overflow-x:hidden}";
const OwcGridItemStyle0 = gridItemCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const DEFAULT_GRID_COLUMN = 12;
const GridItem = /*@__PURE__*/proxyCustomElement(class GridItem extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.padding = 'auto';
    this.xs = undefined;
    this.sm = undefined;
    this.md = undefined;
    this.lg = undefined;
    this.xl = undefined;
    this.verticalPlacement = 'stretch';
    this.horizontalPlacement = 'stretch';
  }
  onCtxColumnsChanged(value) {
    this.gridColumns = value;
    this.updateStyles();
  }
  onAttributesChanged() {
    this.updateStyles();
  }
  onDocumentResized(e) {
    const screenSize = this.getScreenSize(e.detail[SCREEN_SIZE_GLOBAL_KEY]);
    if (this.storedScreenSize !== screenSize) {
      this.storedScreenSize = screenSize;
      this.updateStyles(screenSize);
    }
  }
  componentDidLoad() {
    this.updateStyles();
  }
  getColumnSpan() {
    return {
      xs: this.xs,
      sm: this.sm,
      md: this.md,
      lg: this.lg,
      xl: this.xl
    };
  }
  getScreenSize(inputScreenSize) {
    var _a, _b, _c, _d, _e;
    return inputScreenSize || ((_b = (_a = window[OWC_GLOBAL_KEY]) === null || _a === void 0 ? void 0 : _a[SCREEN_GLOBAL_KEY]) === null || _b === void 0 ? void 0 : _b[SCREEN_SIZE_GLOBAL_KEY]) || getScreenSize((_e = (_d = (_c = getDocument()) === null || _c === void 0 ? void 0 : _c.documentElement) === null || _d === void 0 ? void 0 : _d.getBoundingClientRect()) === null || _e === void 0 ? void 0 : _e.width);
  }
  updateStyles(inputScreenSize) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (isSSR()) {
      return;
    }
    const screenSize = inputScreenSize || this.getScreenSize();
    const padding = getResponsiveAttributeValue(this.padding === 'auto' ? {
      xs: 'var(--one-spacing-padding-2-xs)',
      lg: 'var(--one-spacing-padding-s)'
    } : this.padding, screenSize, 0);
    const columnSpan = getResponsiveAttributeValue(this.getColumnSpan(), screenSize, 1);
    const hPlacement = getResponsiveAttributeValue(this.horizontalPlacement, screenSize, 'stretch');
    const vPlacement = getResponsiveAttributeValue(this.verticalPlacement, screenSize, 'stretch');
    (_b = (_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty(`grid-column-end`, `span ${Math.min(this.gridColumns || DEFAULT_GRID_COLUMN, columnSpan)}`);
    (_d = (_c = this.hostElement) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.setProperty(`justify-self`, `${hPlacement || 'stretch'}`);
    (_f = (_e = this.hostElement) === null || _e === void 0 ? void 0 : _e.style) === null || _f === void 0 ? void 0 : _f.setProperty(`align-self`, `${vPlacement || 'stretch'}`);
    hasValue(padding) && ((_h = (_g = this.hostElement) === null || _g === void 0 ? void 0 : _g.style) === null || _h === void 0 ? void 0 : _h.setProperty(`--grid-item-padding`, typeof padding === 'number' ? `${padding}px` : padding));
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    return h(Host, {
      key: 'a322c7c227726cd77b67d161426cea9b917648db',
      class: hostClasses
    }, h("slot", {
      key: '5c4db755044ef29c32a75cbe518dae3ab0a0f9cc'
    }));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "xs": ["onAttributesChanged"],
      "sm": ["onAttributesChanged"],
      "md": ["onAttributesChanged"],
      "lg": ["onAttributesChanged"],
      "xl": ["onAttributesChanged"],
      "padding": ["onAttributesChanged"],
      "verticalPlacement": ["onAttributesChanged"],
      "horizontalPlacement": ["onAttributesChanged"]
    };
  }
  static get style() {
    return OwcGridItemStyle0;
  }
}, [6, "owc-grid-item", {
  "padding": [520],
  "xs": [514],
  "sm": [514],
  "md": [514],
  "lg": [514],
  "xl": [514],
  "verticalPlacement": [1, "vertical-placement"],
  "horizontalPlacement": [1, "horizontal-placement"]
}, [[4, "documentResized", "onDocumentResized"]], {
  "xs": ["onAttributesChanged"],
  "sm": ["onAttributesChanged"],
  "md": ["onAttributesChanged"],
  "lg": ["onAttributesChanged"],
  "xl": ["onAttributesChanged"],
  "padding": ["onAttributesChanged"],
  "verticalPlacement": ["onAttributesChanged"],
  "horizontalPlacement": ["onAttributesChanged"]
}]);
__decorate([OnContextChange(CTX_KEYS.GRID.COLUMNS)], GridItem.prototype, "onCtxColumnsChanged", null);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-grid-item"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-grid-item":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, GridItem);
        }
        break;
    }
  });
}
const OwcGridItem = GridItem;
const defineCustomElement = defineCustomElement$1;
export { OwcGridItem, defineCustomElement };