const CTX_KEYS = {
  TABLE: {
    CONFIG: 'table:config',
    CALCULATIONS: 'table:calculations'
  },
  GRID: {
    COLUMNS: 'grid:columns'
  },
  MENU: {
    STATUS: 'menu:status'
  }
};
export { CTX_KEYS as C };