import { v as isDefined } from './p-604842db.js';
class FocusTrapQueryItemBuilder {
  constructor() {
    this._attributes = [];
  }
  static create() {
    return new FocusTrapQueryItemBuilder();
  }
  build() {
    return [this._tag, ...this._attributes, ...(this._defaults ? [':not([tabindex^="-"])', ':not([disabled])', ':not([aria-hidden])', ':not([data-skip-focus])'] : [])].filter(Boolean).join('');
  }
  tag(input) {
    this._tag = input;
    return this;
  }
  has(attribute, value) {
    const selectorContent = [attribute, isDefined(value) ? `"${value}"` : ''].filter(Boolean).join('=');
    this._attributes.push(`[${selectorContent}]`);
    return this;
  }
  not(attribute, value) {
    const selectorContent = [attribute, isDefined(value) ? `"${value}"` : ''].filter(Boolean).join('=');
    this._attributes.push(`:not([${selectorContent}])`);
    return this;
  }
  startWith(attribute, value) {
    const selectorContent = [attribute, isDefined(value) ? `"${value}"` : ''].filter(Boolean).join('^=');
    this._attributes.push(`[${selectorContent}]`);
    return this;
  }
  notStartWith(attribute, value) {
    const selectorContent = [attribute, isDefined(value) ? `"${value}"` : ''].filter(Boolean).join('^=');
    this._attributes.push(`:not([${selectorContent}])`);
    return this;
  }
  withDefaults() {
    this._defaults = true;
    return this;
  }
}
class FocusTrapQueryBuilder {
  constructor() {
    this._items = [];
  }
  static create() {
    return new FocusTrapQueryBuilder();
  }
  build() {
    return this._items.map(item => item.build()).filter(Boolean).join(', ');
  }
  add(item) {
    this._items.push(item);
    return this;
  }
  static getNativeElementQuery() {
    return FocusTrapQueryBuilder.create().add(FocusTrapQueryItemBuilder.create().tag('input').not('type', 'hidden').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('textarea').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('button').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('a').withDefaults());
  }
  static getPredefinedTrappedElementsQuery() {
    return FocusTrapQueryBuilder.create().add(FocusTrapQueryItemBuilder.create().tag('a').has('href').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('area').has('href').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('input').not('type', 'hidden').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('select').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('textarea').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('button').withDefaults()).add(FocusTrapQueryItemBuilder.create().tag('iframe')).add(FocusTrapQueryItemBuilder.create().tag('object')).add(FocusTrapQueryItemBuilder.create().tag('embed')).add(FocusTrapQueryItemBuilder.create().has('tabindex').withDefaults()).add(FocusTrapQueryItemBuilder.create().has('tabindex', 0)).add(FocusTrapQueryItemBuilder.create().has('contenteditable'));
  }
}
export { FocusTrapQueryItemBuilder as F, FocusTrapQueryBuilder as a };