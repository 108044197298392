import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

import {
  AmplifyUser,
  SignInUserSession,
} from '../models/aws-amplify.interface';
import { Config } from '../models/config.interface';

import { UserProfile } from './models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private config: Config = {} as Config;

  init(config: Config): Observable<UserProfile | null> {
    this.setConfigParam(config);
    this.configureAmplify();

    const isComingFromLogin =
      window.location.pathname.endsWith('/login') &&
      window.location.search.includes('?code=');
    const isComingFromLogout = window.location.pathname.endsWith('/logout');

    if (isComingFromLogin) {
      return this.getCurrentUser();
    } else if (isComingFromLogout) {
      return this.handleLogout();
    } else {
      //page refresh, change of tab, etc.
      return this.getCurrentUser().pipe(
        switchMap(userProfile => {
          if (userProfile) {
            return of(userProfile);
          } else {
            return from(this.signIn()).pipe(map(() => null));
          }
        }),
        catchError(() => of(null)),
      );
    }
  }

  signOut(): Observable<null> {
    return from(Auth.signOut()).pipe(
      map(() => null),
      catchError(() => of(null)),
    );
  }

  private setConfigParam(config: Config): void {
    this.config = config;
  }

  private handleLogout(): Observable<null> {
    window.location.href = `${this.config.idp.issuerUrl}/idp/startSLO.ping`;
    return of(null);
  }

  private getCurrentUser(): Observable<UserProfile | null> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((amplifyUser: AmplifyUser) => {
        return amplifyUser ? this.formatUserProfile(amplifyUser) : null;
      }),
      catchError(() => from([null])),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private signIn(): Promise<any> {
    const idps = this.config.idp.supportedIdps?.split(',') || [];
    const idp = idps.length === 1 ? idps[0] : '';
    const loginOptions = {
      customProvider: idp,
    };
    return Auth.federatedSignIn(loginOptions);
  }

  private configureAmplify(): void {
    const oauth = {
      clientId: this.config.idp.clientId,
      domain: this.config.idp.userPoolDomain,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: `${window.location.origin}/login`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    };

    const amplifyConfig = {
      aws_project_region: 'eu-central-1',
      aws_cognito_region: 'eu-central-1',
      aws_user_pools_id: this.config.idp.userPoolId,
      aws_user_pools_web_client_id: this.config.idp.clientId,
      oauth,
    };

    Amplify.configure(amplifyConfig);
  }

  private formatUserProfile(amplifyUser: AmplifyUser): UserProfile {
    const signInUser: SignInUserSession = amplifyUser?.signInUserSession;
    const signInUserPayload = signInUser?.idToken?.payload;
    const name = this.extractNameFromIdp(amplifyUser);

    const userProfile = {
      sub: signInUserPayload?.sub,
      name: name,
      givenName: signInUserPayload?.given_name,
      familyName: signInUserPayload?.family_name,
      email: signInUserPayload?.email,
      jwtId: signInUser?.idToken?.jwtToken,
      jwtAccess: signInUser?.accessToken?.jwtToken,
    } as UserProfile;

    return userProfile;
  }

  private extractNameFromIdp(amplifyUser: AmplifyUser): string {
    const name = amplifyUser?.username;
    const identities = amplifyUser?.attributes?.identities;

    return name ?? (JSON.parse(identities)[0]?.userId || '');
  }
}
