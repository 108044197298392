import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../models/config.interface';

import { AuthApiService } from './auth-api.service';
import { UserProfile } from './models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authApiService = inject(AuthApiService);

  init(config: Config): Observable<UserProfile | null> {
    return this.authApiService.init(config);
  }

  signOut(): Observable<null> {
    return this.authApiService.signOut();
  }
}
