import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { a as FocusTrapQueryBuilder } from './p-66a8162e.js';
import { n as isElementVisible } from './p-604842db.js';
import { h as hideElementFromAccessibilityAPI } from './p-756e7dc5.js';
import { G as KeydownHostEvent, J as KeydownManager, E as KeydownAction } from './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
const focusTrapCss = ".sc-owc-focus-trap-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.sc-owc-focus-trap-h:focus-visible{outline:none}.owc-soft-focus.sc-owc-focus-trap-h{outline:none}@supports not selector(:focus-visible){.sc-owc-focus-trap-h:focus{outline:none}.sc-owc-focus-trap-h:focus-within{outline:none}.owc-soft-focus.sc-owc-focus-trap-h{outline:none}}.sc-owc-focus-trap-h .owc-focus-trap__exit-trap.sc-owc-focus-trap{min-height:var(--one-spacing-size-none);max-height:var(--one-spacing-size-none);height:var(--one-spacing-size-none);min-width:var(--one-spacing-size-none);max-width:var(--one-spacing-size-none);width:var(--one-spacing-size-none);padding:var(--one-spacing-padding-none);margin:var(--one-spacing-padding-none);border:none;opacity:0}";
const OwcFocusTrapStyle0 = focusTrapCss;
const DEFAULT_FOCUS_TIMEOUT = 100;
const FOCUS_TRAP_QUERY = FocusTrapQueryBuilder.getPredefinedTrappedElementsQuery().build();
const FocusTrap = /*@__PURE__*/proxyCustomElement(class FocusTrap extends H {
  constructor() {
    super();
    this.__registerHost();
    this.exit = createEvent(this, "exit", 3);
    this.getFirstFocusableItem = trappedElement => this.getFocusableItems(trappedElement)[0];
    this.getAutoFocusableItem = () => {
      const elements = this.getFocusableItems();
      const autoFocusableElementFound = elements.find(e => e.hasAttribute('autofocus'));
      return autoFocusableElementFound || this.getFirstFocusableItem(this.trappedElement);
    };
    this.getLastFocusableItem = trappedElement => {
      const elements = this.getFocusableItems(trappedElement);
      return elements[elements.length - 1];
    };
    this.component = new ComponentUtils(this).withKeydownManager({
      hostEventHandlers: {
        [KeydownHostEvent.FOCUS]: ({
          event
        }) => {
          var _a, _b, _c, _d, _e, _f, _g, _h, _j;
          const focusComingFrom = event.relatedTarget;
          if (this.hostElement.contains(focusComingFrom)) {
            // from the focus-trap component children
            this.disableCircularFocus ? this.exit.emit() : (_b = (_a = this.getLastFocusableItem()) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
          } else {
            switch (this.autoFocusType) {
              case 'first':
                (_d = (_c = this.getFirstFocusableItem(this.trappedElement)) === null || _c === void 0 ? void 0 : _c.focus) === null || _d === void 0 ? void 0 : _d.call(_c);
                break;
              case 'auto':
                (_f = (_e = this.getAutoFocusableItem()) === null || _e === void 0 ? void 0 : _e.focus) === null || _f === void 0 ? void 0 : _f.call(_e);
                break;
              case 'last':
                (_h = (_g = this.getLastFocusableItem(this.trappedElement)) === null || _g === void 0 ? void 0 : _g.focus) === null || _h === void 0 ? void 0 : _h.call(_g);
                break;
              default:
                if (typeof this.autoFocusType === 'function') {
                  const element = this.autoFocusType();
                  if (element instanceof Promise) {
                    element.then(asyncElement => {
                      var _a;
                      (_a = asyncElement === null || asyncElement === void 0 ? void 0 : asyncElement.focus) === null || _a === void 0 ? void 0 : _a.call(asyncElement);
                    });
                  } else {
                    (_j = element === null || element === void 0 ? void 0 : element.focus) === null || _j === void 0 ? void 0 : _j.call(element);
                  }
                }
            }
          }
        }
      },
      keydownHandlers: [{
        key: KeydownManager.DefaultKeys[KeydownAction.CLOSE],
        disabled: () => !(this.exitWithEscape && this.active),
        handler: () => this.exit.emit()
      }]
    });
    this.autoFocusType = 'none';
    this.trappedElement = undefined;
    this.active = false;
    this.disableCircularFocus = false;
    this.disableFocusOnActive = false;
    this.disableFocusOnInactive = false;
    this.exitWithEscape = false;
  }
  handleVisibleChanges(newValue, oldValue) {
    if (!!oldValue !== !!newValue) {
      if (newValue) {
        this.previousFocusedElement = document.activeElement;
        !this.disableFocusOnActive && setTimeout(() => {
          var _a, _b;
          return (_b = (_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
        }, DEFAULT_FOCUS_TIMEOUT);
      } else {
        !this.disableFocusOnInactive && setTimeout(() => {
          var _a, _b;
          (_b = (_a = this.previousFocusedElement) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
          this.previousFocusedElement = undefined;
        }, DEFAULT_FOCUS_TIMEOUT);
      }
    }
  }
  getFocusableItems(trappedElement) {
    var _a;
    const focusableItems = Array.from(((_a = trappedElement !== null && trappedElement !== void 0 ? trappedElement : this.hostElement) === null || _a === void 0 ? void 0 : _a.querySelectorAll(FOCUS_TRAP_QUERY)) || []).filter(isElementVisible);
    if (focusableItems.length === 0) {
      return [this.hostElement];
    } else {
      return focusableItems;
    }
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    const exitTrapElementClasses = this.component.getChildBemClasses('exit-trap', hostClasses);
    return h(Host, {
      key: 'dd0b9f5c181704370d7fc654c834a401310e0fed',
      class: hostClasses,
      tabIndex: this.active ? 0 : -1
    }, h("slot", {
      key: '862872e1d4dbfd5fc226d65fdadc7d941681cb39'
    }), h("input", Object.assign({
      key: 'e2e82b174b43201d9a59e9e9ef479c028785a1e3'
    }, hideElementFromAccessibilityAPI(), {
      "data-skip-focus": "true",
      class: exitTrapElementClasses,
      onFocus: () => {
        var _a;
        return this.disableCircularFocus ? this.exit.emit() : (_a = this.getFirstFocusableItem()) === null || _a === void 0 ? void 0 : _a.focus();
      }
    })));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "active": ["handleVisibleChanges"]
    };
  }
  static get style() {
    return OwcFocusTrapStyle0;
  }
}, [6, "owc-focus-trap", {
  "autoFocusType": [1, "auto-focus-type"],
  "trappedElement": [16],
  "active": [4],
  "disableCircularFocus": [4, "disable-circular-focus"],
  "disableFocusOnActive": [4, "disable-focus-on-active"],
  "disableFocusOnInactive": [4, "disable-focus-on-inactive"],
  "exitWithEscape": [4, "exit-with-escape"]
}, undefined, {
  "active": ["handleVisibleChanges"]
}]);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-focus-trap"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, FocusTrap);
        }
        break;
    }
  });
}
export { FocusTrap as F, defineCustomElement as d };