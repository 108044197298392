import { NgIf } from '@angular/common';
import { Component, inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';

import { ButtonsAndIndicatorsModule } from '@one/angular/dist/buttons-and-indicators';
import { LayoutModule } from '@one/angular/dist/layout';
import { NavigationModule } from '@one/angular/dist/navigation';

import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'dl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  schemas: [NO_ERRORS_SCHEMA],
  imports: [NgIf, ButtonsAndIndicatorsModule, NavigationModule, LayoutModule],
})
export class HeaderComponent implements OnInit {
  readonly authService = inject(AuthService);

  profileData: { name: string; email: string; initials: string } | null = null;

  ngOnInit(): void {
    this.setUserProfileData();
  }

  logout(): void {
    this.authService.signOut();
  }

  private setUserProfileData(): void {
    this.profileData = {
      name: localStorage.getItem('name') ?? '',
      email: localStorage.getItem('email') ?? '',
      initials: localStorage.getItem('initials') ?? '',
    };
  }
}
