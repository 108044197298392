import { NgIf } from '@angular/common';
import { Component, inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';

import { ButtonsAndIndicatorsModule } from '@one/angular/dist/buttons-and-indicators';
import { LayoutModule } from '@one/angular/dist/layout';
import { NavigationModule } from '@one/angular/dist/navigation';

import { AuthService } from '../../../../core/auth/auth.service';
import { UserProfile } from '../../../../core/auth/models/user-profile.model';

@Component({
  selector: 'dl-double-global-area',
  templateUrl: './double-global-area.component.html',
  styleUrls: ['./double-global-area.component.scss'],
  standalone: true,
  schemas: [NO_ERRORS_SCHEMA],
  imports: [NgIf, ButtonsAndIndicatorsModule, NavigationModule, LayoutModule],
})
export class DoubleGlobalAreaComponent implements OnInit {
  readonly authService = inject(AuthService);

  public profileData: UserProfile | null = null;
  public userInitials: string = '';

  private _selectedTab1 = 'menu';

  public ngOnInit(): void {
    this.setUserProfileData();
  }

  set selectedTab1(tab: string) {
    this._selectedTab1 = tab;
  }

  get selectedTab1(): string {
    return this._selectedTab1;
  }

  public logout(): void {
    this.authService.signOut();
  }

  private setUserProfileData(): void {
    this.authService
      .getCurrentUser()
      .then((userProfile: UserProfile | null) => {
        if (userProfile === null) {
          this.profileData = null;
          // TODO in future it should be blocked. User should not see the interface if he is not logged in/authorized/his User Profile is not available
        } else {
          this.profileData = userProfile;
          this.userInitials =
            userProfile?.name?.substring(0, 1).toUpperCase() || '';
        }
      });
  }
}
