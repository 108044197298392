import { g as getWidthFromResizeObserverEntry } from './p-cf58d171.js';
import './p-604842db.js';
import { b as ResizeObserverSafe } from './p-a00c815e.js';
function OnElementResize(opts) {
  return (proto, methodName) => {
    const {
      componentDidLoad,
      disconnectedCallback,
      connectedCallback
    } = proto;
    const observerAttrName = `${methodName}-resize-observer`;
    const observe = (self, {
      target
    }) => {
      var _a;
      const el = target(self);
      if (el) {
        const method = self[methodName];
        (_a = self[observerAttrName]) === null || _a === void 0 ? void 0 : _a.disconnect();
        self[observerAttrName] = new ResizeObserverSafe((entries, _observer) => {
          for (const entry of entries) {
            method === null || method === void 0 ? void 0 : method.call(self, getWidthFromResizeObserverEntry(entry));
          }
        });
        self[observerAttrName].observe(el);
      }
    };
    proto.componentDidLoad = function () {
      observe(this, opts);
      return componentDidLoad === null || componentDidLoad === void 0 ? void 0 : componentDidLoad.call(this);
    };
    proto.connectedCallback = function () {
      observe(this, opts);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      var _a;
      (_a = this[observerAttrName]) === null || _a === void 0 ? void 0 : _a.disconnect();
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}
export { OnElementResize as O };