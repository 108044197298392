import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { C as CTX_KEYS } from './p-e5c1eb43.js';
import { a as FocusTrapQueryBuilder } from './p-66a8162e.js';
import { b as generateElementId, g as getDocument } from './p-604842db.js';
import { a as accessibilityAttributes, b as accessibility } from './p-756e7dc5.js';
import { K as KeyboardCode } from './p-a00c815e.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { a as Context, C as ContextValue } from './p-4ae29297.js';
import { d as defineCustomElement$4 } from './p-43e98172.js';
import { d as defineCustomElement$3 } from './p-fc8ae07f.js';
import { d as defineCustomElement$2 } from './p-2977eb6f.js';
import { d as defineCustomElement$1 } from './p-ddd9f091.js';
const flexMenuCss = ".sc-owc-flex-menu-h{max-width:calc(var(--one-spacing-size-7-xs) * 312);min-width:calc(var(--one-spacing-size-7-xs) * 110);z-index:var(--one-z-index-popover);background-color:var(--one-generic-background-transparent);border-radius:var(--one-spacing-radius-5-xs);display:contents;outline:none}.sc-owc-flex-menu-h owc-popover.sc-owc-flex-menu{min-width:inherit;max-width:inherit;width:inherit;-webkit-box-shadow:inherit;box-shadow:inherit;z-index:inherit;background-color:inherit;border-radius:inherit;border:inherit}@media (forced-colors: active){.sc-owc-flex-menu-h owc-popover.sc-owc-flex-menu{outline:none}}";
const OwcFlexMenuStyle0 = flexMenuCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const OWC = 'owc';
const NATIVE_ELEMENT_QUERY = FocusTrapQueryBuilder.getNativeElementQuery().build();
const FlexMenu = /*@__PURE__*/proxyCustomElement(class FlexMenu extends H {
  constructor() {
    super();
    this.__registerHost();
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.ctxMenuStatus = new ContextValue();
    this.component = new ComponentUtils(this);
    this.setCtxStatus = s => this.ctxMenuStatus.set(Object.assign(Object.assign({}, this.ctxMenuStatus.get()), s));
    this.hostId = generateElementId(this.hostElement);
    this.anchor = undefined;
    this.position = 'bottom-start';
    this.visible = false;
    this.notFocusable = false;
    this.disableFocusOnActive = false;
    this.disableFocusOnInactive = false;
    this.disableCircularFocus = false;
    this.strategy = 'absolute';
    this.trigger = 'click';
    this.offset = undefined;
    this.popoverProps = undefined;
    this.cardProps = undefined;
    this.anchorWidth = false;
    this.preventOverflow = false;
    this.noKeydownManager = false;
    this.hostRole = 'dialog';
    this.autoFocusTo = 'first';
  }
  visibleWatcher(newValue, oldValue) {
    !!newValue !== !!oldValue && this.visibleChange.emit(!!newValue);
    if (this.visible) {
      this.updateWidth();
    } else {
      this.autoFocusTo = 'first';
    }
    this.setCtxStatus({
      visible: this.visible
    });
  }
  onAnchorChanged() {
    this.updateWidth();
    this.initKeydownManager();
  }
  componentDidLoad() {
    this.setCtxStatus({
      visible: this.visible
    });
    this.onAnchorChanged();
  }
  componentWillRender() {
    this.updateAriaAttributes();
  }
  initKeydownManager() {
    this.component.withKeydownManagerLazy(() => this.getAnchorElement(), [{
      keydownHandlers: [{
        key: [KeyboardCode.ArrowDown],
        handler: () => {
          this.autoFocusTo = 'first';
          this.visible = true;
        },
        disabled: () => this.visible || this.noKeydownManager
      }, {
        key: [KeyboardCode.ArrowUp],
        handler: () => {
          this.autoFocusTo = 'last';
          this.visible = true;
        },
        disabled: () => this.visible || this.noKeydownManager
      }]
    }]);
  }
  getAnchorElement() {
    var _a;
    return typeof this.anchor === 'string' ? (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.getElementById(this.anchor) : this.anchor;
  }
  updateAriaAttributes() {
    const anchor = this.getAnchorElement();
    if (anchor) {
      const accessibleElement = anchor.tagName.toLowerCase().startsWith(OWC) && typeof anchor.getAttributeNode === 'function' && anchor.querySelector(NATIVE_ELEMENT_QUERY);
      Object.entries(accessibilityAttributes({
        haspopup: true,
        expanded: this.visible
      })).forEach(([key, value]) => {
        var _a;
        return (_a = accessibleElement || anchor) === null || _a === void 0 ? void 0 : _a.setAttribute(key, value);
      });
    }
  }
  updateWidth() {
    var _a, _b, _c, _d, _e;
    if (this.visible && this.anchorWidth) {
      const anchor = this.getAnchorElement();
      const width = ((_a = anchor === null || anchor === void 0 ? void 0 : anchor.getBoundingClientRect()) === null || _a === void 0 ? void 0 : _a.width) || ((_b = this.hostElement.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width) || 0;
      (_c = this.hostElement.style) === null || _c === void 0 ? void 0 : _c.setProperty('width', `${width}px`);
      (_d = this.hostElement.style) === null || _d === void 0 ? void 0 : _d.setProperty('min-width', `${width}px`);
      (_e = this.hostElement.style) === null || _e === void 0 ? void 0 : _e.setProperty('max-width', `${width}px`);
    }
  }
  getCardElement() {
    return h("owc-card", Object.assign({}, this.cardProps), h("slot", {
      name: "header"
    }), h("slot", null), h("slot", {
      name: "footer"
    }));
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    return h(Host, Object.assign({
      key: '460fd6fd64f529f0704aa5a91862f33e7b4d58f6',
      class: hostClasses,
      tabIndex: !this.notFocusable && this.visible ? this.component.getInitialTabIndex(0) : -1
    }, accessibility({
      role: this.hostRole
    })), h("owc-popover", {
      key: '07a409a8123a9f15968d3ca070633d2991ccd347',
      anchor: this.anchor,
      visible: this.visible,
      strategy: this.strategy,
      offset: this.offset,
      tabIndex: -1,
      position: this.position,
      trigger: this.trigger,
      disableAccessibility: true,
      preventOverflow: this.preventOverflow,
      onVisibleChange: e => this.visible = e.detail,
      onClickOutside: () => this.clickOutside.emit()
    }, h("owc-focus-trap", {
      key: '0d9659bc74ce6f3f820362579aaed80f8e4773d1',
      active: this.visible,
      autoFocusType: this.autoFocusTo,
      disableFocusOnActive: this.disableFocusOnActive,
      disableFocusOnInactive: this.disableFocusOnInactive,
      disableCircularFocus: this.disableCircularFocus,
      exitWithEscape: true,
      onExit: () => this.visible = false
    }, this.getCardElement())));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "visible": ["visibleWatcher"],
      "anchor": ["onAnchorChanged"]
    };
  }
  static get style() {
    return OwcFlexMenuStyle0;
  }
}, [6, "owc-flex-menu", {
  "hostId": [1537, "id"],
  "anchor": [1],
  "position": [513],
  "visible": [1540],
  "notFocusable": [516, "not-focusable"],
  "disableFocusOnActive": [516, "disable-focus-on-active"],
  "disableFocusOnInactive": [516, "disable-focus-on-inactive"],
  "disableCircularFocus": [516, "disable-circular-focus"],
  "strategy": [513],
  "trigger": [513],
  "offset": [16],
  "popoverProps": [16],
  "cardProps": [16],
  "anchorWidth": [4, "anchor-width"],
  "preventOverflow": [4, "prevent-overflow"],
  "noKeydownManager": [4, "no-keydown-manager"],
  "hostRole": [1, "role"],
  "autoFocusTo": [32]
}, undefined, {
  "visible": ["visibleWatcher"],
  "anchor": ["onAnchorChanged"]
}]);
__decorate([Context(CTX_KEYS.MENU.STATUS)], FlexMenu.prototype, "ctxMenuStatus", void 0);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-flex-menu", "owc-card", "owc-focus-trap", "owc-icon", "owc-popover"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-flex-menu":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, FlexMenu);
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
      case "owc-popover":
        if (!customElements.get(tagName)) {
          defineCustomElement$1();
        }
        break;
    }
  });
}
export { FlexMenu as F, defineCustomElement as d };