const REF_KEY = 'current';
const useRef = () => {
  let ref;
  return new Proxy(() => undefined, {
    apply: (_target, _thisArg, [elm, refsToSet, watcher]) => {
      watcher === null || watcher === void 0 ? void 0 : watcher(ref, elm);
      ref = elm;
      (refsToSet || []).forEach(refToSet => refToSet === null || refToSet === void 0 ? void 0 : refToSet(elm));
    },
    get: (target, prop, receiver) => prop === REF_KEY ? ref : Reflect.get(target, prop, receiver)
  });
};
export { useRef as u };