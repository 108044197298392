import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-25bf8c52.js';
import { M as MultiChildrenWrapper, d as defineCustomElement$7 } from './p-fa64486b.js';
import { i as isSameArray } from './p-d7a5de56.js';
import { v as isDefined } from './p-604842db.js';
import './p-a00c815e.js';
import { u as useRef } from './p-e952b680.js';
import { a as ComponentUtils } from './p-cf58d171.js';
import { d as defineCustomElement$i } from './p-c63d33da.js';
import { d as defineCustomElement$h } from './p-db4cdc02.js';
import { d as defineCustomElement$g } from './p-43e98172.js';
import { d as defineCustomElement$f } from './p-c922fcf3.js';
import { d as defineCustomElement$e } from './p-54f516a8.js';
import { d as defineCustomElement$d } from './p-337473a5.js';
import { d as defineCustomElement$c } from './p-bed53612.js';
import { d as defineCustomElement$b } from './p-fc8ae07f.js';
import { d as defineCustomElement$a } from './p-2977eb6f.js';
import { d as defineCustomElement$9 } from './p-1be53978.js';
import { d as defineCustomElement$8 } from './p-8495f6f6.js';
import { d as defineCustomElement$6 } from './p-744a991c.js';
import { d as defineCustomElement$5 } from './p-fe94825f.js';
import { d as defineCustomElement$4 } from './p-ddd9f091.js';
import { d as defineCustomElement$3 } from './p-e9171c24.js';
import { d as defineCustomElement$2 } from './p-7708f872.js';
import { d as defineCustomElement$1 } from './p-40870078.js';
const selectDropdownCss = ".sc-owc-select-dropdown-h{display:-ms-inline-flexbox;display:inline-flex;width:calc(var(--one-spacing-size-7-xs) * 250)}.owc-select-dropdown--loading.sc-owc-select-dropdown-h owc-list-item.sc-owc-select-dropdown{visibility:hidden}";
const OwcSelectDropdownStyle0 = selectDropdownCss;
const MENU_ITEM_LIMIT = 6;
const SelectDropdown = /*@__PURE__*/proxyCustomElement(class SelectDropdown extends H {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.baseDropdownRef = useRef();
    this.component = new ComponentUtils(this, {
      patchFocusMethod: () => this.focusDropdownElement(),
      patchBlurMethod: () => this.blurDropdownElement()
    });
    this.focusDropdownElement = () => {
      var _a;
      if (!this.disabled && !this.focused) {
        (_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        this.focused = true;
      }
    };
    this.blurDropdownElement = () => {
      var _a;
      if (this.focused) {
        (_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        this.focused = false;
      }
    };
    this.visible = false;
    this.validity = {
      state: 'valid'
    };
    this.disabled = false;
    this.label = undefined;
    this.limit = MENU_ITEM_LIMIT;
    this.name = undefined;
    this.placeholder = undefined;
    this.required = false;
    this.value = [];
    this.variant = 'filled';
    this.size = 'medium';
    this.rounded = false;
    this.searchable = false;
    this.notFoundText = 'Not Found';
    this.noClearIcon = false;
    this.multiple = false;
    this.selectAllText = 'All';
    this.hideSelectAll = false;
    this.showApplyButton = false;
    this.minlength = undefined;
    this.maxlength = undefined;
    this.controlled = false;
    this.notSelectableValues = [];
    this.disableValidation = false;
    this.validationTrigger = 'input, blur';
    this.controlledValidity = false;
    this.selectableValues = [];
    this.applyButtonText = 'Apply';
    this.inputProps = undefined;
    this.menuProps = undefined;
    this.tooltipProps = undefined;
    this.clearButtonProps = undefined;
    this.menuTriggerButtonProps = undefined;
    this.focused = false;
    this.loading = true;
    this.internalValue = [];
  }
  onValueChanged() {
    let newInternalValue = [];
    Array.isArray(this.value) && (newInternalValue = this.value);
    typeof this.value === 'string' && (newInternalValue = this.value.split(',').map(v => v.trim()));
    newInternalValue = newInternalValue.filter(isDefined);
    !isSameArray(this.internalValue, newInternalValue) && (this.internalValue = newInternalValue);
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    return Promise.resolve((_b = (_a = this === null || this === void 0 ? void 0 : this.baseDropdownRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.validate());
  }
  componentWillLoad() {
    this.onValueChanged();
  }
  componentDidLoad() {
    this.loading = false;
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      disabled: this.disabled,
      loading: this.loading
    });
    return h(Host, {
      key: 'dedbf8bce231d34f860928118b09b6e3e9b4f244',
      class: hostClasses,
      tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex()
    }, h("owc-base-dropdown", {
      key: 'a8fcf47e114447a3ec55a094cb1f0e08dfe9926a',
      ref: this.baseDropdownRef,
      visible: this.visible,
      validity: this.validity,
      disableValidation: this.disableValidation,
      disabled: this.disabled,
      label: this.label,
      limit: this.limit,
      name: this.name,
      placeholder: this.placeholder,
      required: this.required,
      value: this.internalValue,
      controlled: this.controlled,
      variant: this.variant,
      size: this.size,
      rounded: this.rounded,
      searchable: this.searchable,
      notFoundText: this.notFoundText,
      noClearIcon: this.noClearIcon,
      multiple: this.multiple,
      selectAllText: this.selectAllText,
      hideSelectAll: this.hideSelectAll,
      showApplyButton: this.showApplyButton,
      applyButtonText: this.applyButtonText,
      minlength: this.minlength,
      maxlength: this.maxlength,
      selectableValues: this.selectableValues,
      notSelectableValues: this.notSelectableValues,
      validationTrigger: this.validationTrigger,
      controlledValidity: this.controlledValidity,
      inputProps: this.inputProps,
      menuProps: this.menuProps,
      tooltipProps: this.tooltipProps,
      clearButtonProps: this.clearButtonProps,
      menuTriggerButtonProps: this.menuTriggerButtonProps,
      onValueChange: e => {
        if (!isSameArray(this.internalValue, e.detail)) {
          !this.controlled && (this.value = e.detail);
          this.valueChange.emit(e.detail);
        }
      },
      onVisibleChange: e => this.visibleChange.emit(e.detail),
      onClickOutside: () => this.clickOutside.emit(),
      onValidityChange: e => this.validityChange.emit(e.detail),
      onClearClick: () => this.clearClick.emit(),
      onFocus: () => {
        this.focused = true;
        this.hostElement.dispatchEvent(new KeyboardEvent('focus'));
      },
      onBlur: () => {
        this.focused = false;
        this.hostElement.dispatchEvent(new KeyboardEvent('blur'));
      }
    }, h("slot", {
      key: '844ba823ef487c04df9679135c1223b325fe005a',
      name: "prefix"
    }), h("slot", {
      key: '5afdf5cea9883dd4acccd488e86cd5a06ef6de0e',
      name: "suffix"
    }), h("slot", {
      key: 'b3cb20d6f7a591f3b7f0f6986e258c6f4080b70c',
      name: "assistive-text"
    }), h(MultiChildrenWrapper, {
      key: '597cf9f36a268b89ad064642593c05905a0fab61'
    }, h("slot", {
      key: 'bccb06345585a30cab6e3e11c0dca9670cb74149'
    }))));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "value": ["onValueChanged"]
    };
  }
  static get style() {
    return OwcSelectDropdownStyle0;
  }
}, [6, "owc-select-dropdown", {
  "visible": [1540],
  "validity": [16],
  "disabled": [516],
  "label": [513],
  "limit": [514],
  "name": [513],
  "placeholder": [513],
  "required": [516],
  "value": [1],
  "variant": [513],
  "size": [513],
  "rounded": [516],
  "searchable": [516],
  "notFoundText": [513, "not-found-text"],
  "noClearIcon": [516, "no-clear-icon"],
  "multiple": [516],
  "selectAllText": [513, "select-all-text"],
  "hideSelectAll": [4, "hide-select-all"],
  "showApplyButton": [516, "show-apply-button"],
  "minlength": [514],
  "maxlength": [514],
  "controlled": [516],
  "notSelectableValues": [16],
  "disableValidation": [516, "disable-validation"],
  "validationTrigger": [513, "validation-trigger"],
  "controlledValidity": [516, "controlled-validity"],
  "selectableValues": [16],
  "applyButtonText": [513, "apply-button-text"],
  "inputProps": [16],
  "menuProps": [16],
  "tooltipProps": [16],
  "clearButtonProps": [16],
  "menuTriggerButtonProps": [16],
  "focused": [32],
  "loading": [32],
  "internalValue": [32],
  "validate": [64]
}, undefined, {
  "value": ["onValueChanged"]
}]);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-select-dropdown", "owc-base-dropdown", "owc-button", "owc-card", "owc-checkbox", "owc-component-footer", "owc-component-header", "owc-divider", "owc-focus-trap", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip", "owc-typography"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-select-dropdown":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, SelectDropdown);
        }
        break;
      case "owc-base-dropdown":
        if (!customElements.get(tagName)) {
          defineCustomElement$i();
        }
        break;
      case "owc-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$h();
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$g();
        }
        break;
      case "owc-checkbox":
        if (!customElements.get(tagName)) {
          defineCustomElement$f();
        }
        break;
      case "owc-component-footer":
        if (!customElements.get(tagName)) {
          defineCustomElement$e();
        }
        break;
      case "owc-component-header":
        if (!customElements.get(tagName)) {
          defineCustomElement$d();
        }
        break;
      case "owc-divider":
        if (!customElements.get(tagName)) {
          defineCustomElement$c();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$b();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$a();
        }
        break;
      case "owc-icon-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$9();
        }
        break;
      case "owc-input":
        if (!customElements.get(tagName)) {
          defineCustomElement$8();
        }
        break;
      case "owc-list":
        if (!customElements.get(tagName)) {
          defineCustomElement$7();
        }
        break;
      case "owc-list-item":
        if (!customElements.get(tagName)) {
          defineCustomElement$6();
        }
        break;
      case "owc-menu":
        if (!customElements.get(tagName)) {
          defineCustomElement$5();
        }
        break;
      case "owc-popover":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-radio":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-tooltip":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
      case "owc-typography":
        if (!customElements.get(tagName)) {
          defineCustomElement$1();
        }
        break;
    }
  });
}
export { SelectDropdown as S, defineCustomElement as d };