var __extends = this && this.__extends || function () {
  var e = function (s, a) {
    e = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (e, s) {
      e.__proto__ = s;
    } || function (e, s) {
      for (var a in s) if (Object.prototype.hasOwnProperty.call(s, a)) e[a] = s[a];
    };
    return e(s, a);
  };
  return function (s, a) {
    if (typeof a !== "function" && a !== null) throw new TypeError("Class extends value " + String(a) + " is not a constructor or null");
    e(s, a);
    function t() {
      this.constructor = s;
    }
    s.prototype = a === null ? Object.create(a) : (t.prototype = a.prototype, new t());
  };
}();
export { b as sanitizeIconToken, s as setGlobalTheme, a as setTheme, t as themeMapping } from "./Theming-7f5d2030.js";
export { R as RIPPLE_CONTAINER_CLASSNAME, a as applyRipple } from "./Ripple-cf962767.js";
export { C as ComponentPerformance, a as ComponentUtils, c as componentMethodWrapper, j as debounce, h as getAttribute, f as getAttributes, b as getBreakpoints, e as getResponsiveAttributeValue, d as getScreenSize, g as getWidthFromResizeObserverEntry, i as isChildMutated, p as parseStyles, r as removeAttributes } from "./ComponentUtils-7e5aeb7a.js";
export { g as DRAGGABLE_KEY, h as DRAGGABLE_UNIQUE_DATA_KEY, d as DRAGGING_CLASS, f as DROPZONE_ACTIVE_CLASS, j as DROPZONE_BEHAVIOR_KEY, e as DROPZONE_CLASS, i as DROP_BEHAVIOR_KEY, c as DropBehavior, D as DropzoneBehavior, F as FocusType, I as GLOBAL_FOCUS_CLASS, K as KeyboardCode, E as KeydownAction, G as KeydownHostEvent, H as KeydownLifecycleMethod, J as KeydownManager, L as KeydownTemplate, a as MutationObserverSafe, M as MutationObserverSignatureClass, b as ResizeObserverSafe, R as ResizeObserverSignatureClass, y as addClass, P as arrangeLimit, u as attachListeners, B as createBEMElement, C as createBEMModifier, N as deepMerge, t as detachListeners, v as doWhenDefaultIsNotPrevented, p as findElementInEventPath, q as findNodeInEventPath, A as getDesignTokenValue, w as getRelativePosition, O as inViewport, m as makeDraggable, k as makeDropzone, z as removeClass, l as removeDraggable, r as requestTimeout, x as scrollToElement, s as stopEvent, o as stopImmediatePropagation, n as stopPropagation } from "./MutationObserverSafe-8cdfc100.js";
export { a as FocusTrapQueryBuilder, F as FocusTrapQueryItemBuilder } from "./focus-trap-query-builder.class-70c7d508.js";
import { r as randomString, g as getDocument, a as getBody, b as generateElementId, s as setElementAttributes } from "./element-d66cb8c2.js";
export { C as CTX_KEY, D as DOC_MUTATION_OBSERVER_GLOBAL_KEY, d as DOC_RESIZE_OBSERVER_GLOBAL_KEY, G as GLOBAL_EVENTS, N as NodeType, c as OWC_GLOBAL_KEY, O as OwcPerformanceApi, P as PERFORMANCE_API_GLOBAL_KEY, h as PERFORMANCE_API_STORAGE_KEY, R as RUNTIME_FWK_GLOBAL_KEY, S as SCREEN_GLOBAL_KEY, f as SCREEN_SIZE_GLOBAL_KEY, e as SCREEN_WIDTH_GLOBAL_KEY, j as SUBSCRIPTIONS_KEY, b as generateElementId, a as getBody, k as getComputedStyleSafe, g as getDocument, p as getSiblings, l as hasEllipsis, z as hasValue, u as isAlphaNumeric, v as isDefined, o as isElementInDocument, m as isElementReady, n as isElementVisible, y as isEmptyObject, B as isFloat, F as isNumber, x as isObject, A as isPositiveFloat, E as isPositiveNumber, i as isSSR, w as isString, r as randomString, s as setElementAttributes, q as stringToNumberArray, t as stringToObject } from "./element-d66cb8c2.js";
export { t as taskRunner } from "./task-e2be179f.js";
export { D as DATE_SEPARATOR, F as FORMAT_MAP, O as OnClosestFormSubmit, V as Validator, d as defineFormat, e as endOfDayUTC, g as getBrowserDateFormat, a as getDateFnsLocale, c as getTimeInterval, h as handleLocation, i as isTimeEqual, m as moveTimeToToday, b as moveTimeToTodayUTC, s as startOfDayUTC } from "./closest-form-submit-6edae46f.js";
export { C as ClickOutside } from "./click-outside-dac81bb5.js";
export { O as OnClosestFormReset } from "./closest-form-reset-07139d2b.js";
export { a as Context, C as ContextValue, O as OnContextChange } from "./context-49ac4728.js";
export { F as FocusVisible } from "./focus-visible-9dd922b5.js";
export { O as OnElementMutation } from "./element-mutation-1bb2bf02.js";
export { O as OnElementResize } from "./element-resize-a5cba649.js";
export { L as LongPress } from "./long-press-56d0281a.js";
export { b as accessibility, a as accessibilityAttributes, h as hideElementFromAccessibilityAPI, r as resetAccessibilityRole } from "./accessibility-55d2c310.js";
export { u as useRef } from "./ref-423beccd.js";
export { g as generateDropdownKeydownManager } from "./dropdown-keydown-generator-a5f4ae39.js";
import { S as SubjectBase } from "./subscription-list.class-20a90866.js";
export { B as BehaviorSubject, a as SubscriptionList } from "./subscription-list.class-20a90866.js";
export { a as arrayIncludes, i as isSameArray } from "./array-712cf808.js";
export { n as noop } from "./function-ded8be26.js";
export { a as alpha, d as decomposeColor, h as hexToRgb } from "./color-97df210e.js";
import "./index-bc9b1baf.js";
import "./index-6d31ecbf.js";
var GenericComponent = function () {
  function e() {}
  return e;
}();
var CONSUMER_ID_LENGTH = 32;
var Subject = function (e) {
  __extends(s, e);
  function s() {
    return e !== null && e.apply(this, arguments) || this;
  }
  s.prototype.next = function (e) {
    this.consumers.forEach(function (s) {
      return s === null || s === void 0 ? void 0 : s(e);
    });
  };
  s.prototype.subscribe = function (e) {
    var s = this;
    var a = randomString(CONSUMER_ID_LENGTH);
    this.consumers.set(a, e);
    return {
      id: a,
      unsubscribe: function () {
        return s.consumers.delete(a);
      }
    };
  };
  return s;
}(SubjectBase);
var DEFAULT_SNACKBAR_LIMIT = 3;
var NOTIFICATION_DRAWER_TAG = "owc-notification-drawer";
var SnackbarService = function () {
  function e() {}
  e.prototype.getDrawer = function (e) {
    if (e === void 0) {
      e = DEFAULT_SNACKBAR_LIMIT;
    }
    var s, a, t;
    var r = (s = getDocument()) === null || s === void 0 ? void 0 : s.querySelector(NOTIFICATION_DRAWER_TAG);
    if (!r) {
      r = (a = getDocument()) === null || a === void 0 ? void 0 : a.createElement(NOTIFICATION_DRAWER_TAG);
      if (r) {
        r.limit = e;
        (t = getBody()) === null || t === void 0 ? void 0 : t.appendChild(r);
      }
    }
    return r;
  };
  e.prototype.setLimit = function (e) {
    var s = this.getDrawer();
    s && (s.limit = e);
  };
  e.prototype.clearAll = function () {
    var e;
    (e = this.getDrawer()) === null || e === void 0 ? void 0 : e.querySelectorAll("owc-snackbar").forEach(function (e) {
      return e.remove();
    });
  };
  e.prototype.show = function (e) {
    var s = e.message,
      a = e.snackbarProps,
      t = a === void 0 ? {} : a,
      r = e.actions,
      o = r === void 0 ? [] : r;
    var n;
    var i = getDocument();
    if (!i) {
      return undefined;
    }
    var c = i.createElement("owc-snackbar");
    c.id = generateElementId(c);
    setElementAttributes(c, t);
    var u = i.createElement("div");
    u.innerHTML = s;
    c.appendChild(u);
    if (o.length) {
      var l = i.createElement("div");
      l.slot = "action";
      l.append.apply(l, o.map(function (e) {
        var s = e.content,
          a = e.buttonProps,
          t = a === void 0 ? {} : a;
        var r = i.createElement("owc-button");
        if (r) {
          r.innerText = s;
          setElementAttributes(r, t);
        }
        return r;
      }));
      c.appendChild(l);
    }
    (n = this.getDrawer()) === null || n === void 0 ? void 0 : n.addChild(c);
    return c;
  };
  return e;
}();
var snackbarService = new SnackbarService();
export { GenericComponent, Subject, snackbarService };