// eslint-disable-next-line no-shadow
var GLOBAL_EVENTS;
(function (GLOBAL_EVENTS) {
  GLOBAL_EVENTS["DOCUMENT_CHANGED"] = "documentChanged";
  GLOBAL_EVENTS["DOCUMENT_RESIZED"] = "documentResized";
  GLOBAL_EVENTS["THEME_CHANGED"] = "themeChanged";
})(GLOBAL_EVENTS || (GLOBAL_EVENTS = {}));
const OWC_GLOBAL_KEY = 'owc';
const RUNTIME_FWK_GLOBAL_KEY = 'fwk';
const DOC_MUTATION_OBSERVER_GLOBAL_KEY = 'documentMutationObserver';
const DOC_RESIZE_OBSERVER_GLOBAL_KEY = 'documentResizeObserver';
const SCREEN_GLOBAL_KEY = 'screen';
const SCREEN_WIDTH_GLOBAL_KEY = 'width';
const SCREEN_SIZE_GLOBAL_KEY = 'size';
const PERFORMANCE_API_GLOBAL_KEY = 'performance';
const PERFORMANCE_API_STORAGE_KEY = 'owc-performance';
const SUBSCRIPTIONS_KEY = 's-subscriptions';
const CTX_KEY = 's-ctx';
const isDefined = v => v !== undefined && v !== null;
const isNumberAndDefined = (v, ownCondition = true) => isDefined(v) && typeof v === 'number' && !isNaN(v) && ownCondition;
const isStringAndDefined = v => isDefined(v) && typeof v === 'string';
const isParsableFloat = v => !isNaN(parseFloat(v)) && parseFloat(v) >= 0 && /[0-9]*\.?[0-9]+/.test(v);
const isParsableNumber = v => !isNaN(parseInt(v, 10)) && parseInt(v, 10) >= 0 && /[0-9]+$/.test(v);
const isString = v => isDefined(v) && typeof v === 'string' && v.trim().length !== 0;
const isObject = v => isDefined(v) && typeof v === 'object';
const isEmptyObject = v => isObject(v) && Object.keys(v).length === 0;
const hasValue = v => {
  if (!isDefined(v)) {
    return false;
  }
  if (Array.isArray(v)) {
    return v.length !== 0;
  }
  if (typeof v === 'object') {
    return Object.keys(v).length > 0;
  }
  if (typeof v === 'string') {
    return !!v.trim();
  }
  if (typeof v === 'number') {
    return !Number.isNaN(v);
  }
  return true;
};
const isPositiveFloat = v => isNumberAndDefined(v, +v >= 0) || isStringAndDefined(v) && isParsableFloat(v);
const isFloat = v => isNumberAndDefined(v) || isStringAndDefined(v) && !isNaN(parseFloat(v)) && /[0-9]*\.?[0-9]+/.test(v);
const isPositiveNumber = v => isNumberAndDefined(v, +v >= 0) || isStringAndDefined(v) && isParsableNumber(v);
const isNumber = v => isNumberAndDefined(v) || isStringAndDefined(v) && !isNaN(parseInt(v, 10)) && /[0-9]+$/.test(v);
const DEFAULT_LENGTH = 16;
const randomString = (length = DEFAULT_LENGTH) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from(new Array(length)).map(() => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
};
const stringToNumberArray = (arg, separator = ',') => {
  const opts = typeof arg === 'string' ? arg.split(separator).map(p => p.trim()).filter(hasValue) : arg;
  return opts && opts.length ? opts.filter(isNumber).map(opt => +opt) : []; // prevent empty string error
};
const stringToObject = arg => isString(arg) ? JSON.parse(arg) : arg;
const isAlphaNumeric = arg => !!arg.match(/^[0-9A-Za-z]+$/);
const isSSR = () => typeof window === 'undefined';
class OwcPerformanceApi {
  constructor() {
    this._isStatusLogged = false;
  }
  logStatus() {
    if (!this._isStatusLogged) {
      if (this.isEnabled()) {
        console.log('OWC PERFORMANCE API is enabled!!!!');
      }
      this._isStatusLogged = true;
    }
    return this;
  }
  enable() {
    if (!isSSR()) {
      window.sessionStorage.setItem(PERFORMANCE_API_STORAGE_KEY, JSON.stringify(true));
      window.location.reload();
    }
    return this;
  }
  disable() {
    if (!isSSR()) {
      window.sessionStorage.removeItem(PERFORMANCE_API_STORAGE_KEY);
      window.location.reload();
    }
    return this;
  }
  isEnabled() {
    if (!isSSR()) {
      const rawValue = window.sessionStorage.getItem(PERFORMANCE_API_STORAGE_KEY);
      try {
        const typedValue = JSON.parse(rawValue);
        return typedValue === true;
      } catch (_a) {
        // nothing to do
      }
    }
    return false;
  }
}
if (!isSSR()) {
  !(OWC_GLOBAL_KEY in window) && (window[OWC_GLOBAL_KEY] = {});
  if (!(PERFORMANCE_API_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
    window[OWC_GLOBAL_KEY][PERFORMANCE_API_GLOBAL_KEY] = new OwcPerformanceApi();
  }
  window[OWC_GLOBAL_KEY][PERFORMANCE_API_GLOBAL_KEY].logStatus();
}

/* eslint-disable no-shadow */
var NodeType;
(function (NodeType) {
  NodeType[NodeType["ELEMENT"] = 1] = "ELEMENT";
  NodeType[NodeType["ATTRIBUTE"] = 2] = "ATTRIBUTE";
  NodeType[NodeType["TEXT"] = 3] = "TEXT";
  NodeType[NodeType["CDATA_SECTION"] = 4] = "CDATA_SECTION";
  NodeType[NodeType["ENTITY_REFERENCE"] = 5] = "ENTITY_REFERENCE";
  NodeType[NodeType["ENTITY"] = 6] = "ENTITY";
  NodeType[NodeType["PROCESSING_INSTRUCTION"] = 7] = "PROCESSING_INSTRUCTION";
  NodeType[NodeType["COMMENT"] = 8] = "COMMENT";
  NodeType[NodeType["DOCUMENT"] = 9] = "DOCUMENT";
  NodeType[NodeType["DOCUMENT_TYPE"] = 10] = "DOCUMENT_TYPE";
  NodeType[NodeType["DOCUMENT_FRAGMENT"] = 11] = "DOCUMENT_FRAGMENT";
  NodeType[NodeType["NOTATION"] = 12] = "NOTATION";
})(NodeType || (NodeType = {}));
const DURATION_DEFAULT = 20;
const COUNTER_DEFAULT = 15;
const getDocument = () => typeof document !== 'undefined' ? document : undefined;
const getBody = () => {
  var _a;
  return (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.body;
};
const getComputedStyleSafe = (elt, pseudoElt) => !isSSR() && typeof getComputedStyle === 'function' ? getComputedStyle(elt, pseudoElt) : undefined;
const hasEllipsis = element => element && (element === null || element === void 0 ? void 0 : element.offsetWidth) < (element === null || element === void 0 ? void 0 : element.scrollWidth);
const generateElementId = el => {
  var _a;
  return [(_a = typeof el === 'string' ? el : el === null || el === void 0 ? void 0 : el.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase(), randomString()].filter(Boolean).join('-');
};
const isElementReady = async (cb, counter = COUNTER_DEFAULT, duration = DURATION_DEFAULT) => {
  let i = 0;
  while (!cb() && i < counter) {
    await new Promise(resolve => setTimeout(resolve, duration));
    i++;
  }
  return cb();
};
const isElementVisible = element => {
  const style = getComputedStyleSafe(element);
  if (style) {
    if (style.display === 'none') {
      return false;
    }
    if (style.visibility !== 'visible') {
      return false;
    }
    if (element.offsetWidth + element.offsetHeight + element.getBoundingClientRect().height + element.getBoundingClientRect().width === 0) {
      return false;
    }
  }
  return true;
};
const isElementInDocument = (doc, element) => {
  let currentElement = element;
  while (currentElement && currentElement.parentNode && isElementVisible(element)) {
    if (currentElement.parentNode === doc) {
      return true;
    } else if (currentElement.parentNode instanceof ShadowRoot) {
      currentElement = currentElement.parentNode.host;
    } else {
      currentElement = currentElement.parentNode;
    }
  }
  return false;
};
const setElementAttributes = (element, attributes) => {
  if (!element) {
    return;
  }
  const keys = Object.keys(attributes || {});
  for (const key of keys) {
    if (key.startsWith('on')) {
      const eventName = key.replace('on', '');
      element.addEventListener(`${eventName[0].toLowerCase()}${eventName.slice(1)}`, attributes[key]);
    } else if (key.startsWith('style')) {
      for (const [variable, value] of Object.entries(attributes[key])) {
        element.style.setProperty(variable, value);
      }
    } else {
      if (key.startsWith('class')) {
        element.classList.add(attributes[key]);
      } else {
        element[key] = attributes[key];
      }
    }
  }
};
const getSiblings = (element, filter = () => true) => {
  var _a;
  let iterator = (_a = element === null || element === void 0 ? void 0 : element.parentElement) === null || _a === void 0 ? void 0 : _a.firstElementChild;
  const siblings = [];
  for (; iterator; iterator = iterator.nextElementSibling) {
    if (iterator.nodeType === NodeType.ELEMENT && (filter === null || filter === void 0 ? void 0 : filter(iterator))) {
      siblings.push(iterator);
    }
  }
  return siblings;
};
export { isPositiveFloat as A, isFloat as B, CTX_KEY as C, DOC_MUTATION_OBSERVER_GLOBAL_KEY as D, isPositiveNumber as E, isNumber as F, GLOBAL_EVENTS as G, NodeType as N, OwcPerformanceApi as O, PERFORMANCE_API_GLOBAL_KEY as P, RUNTIME_FWK_GLOBAL_KEY as R, SCREEN_GLOBAL_KEY as S, getBody as a, generateElementId as b, OWC_GLOBAL_KEY as c, DOC_RESIZE_OBSERVER_GLOBAL_KEY as d, SCREEN_WIDTH_GLOBAL_KEY as e, SCREEN_SIZE_GLOBAL_KEY as f, getDocument as g, PERFORMANCE_API_STORAGE_KEY as h, isSSR as i, SUBSCRIPTIONS_KEY as j, getComputedStyleSafe as k, hasEllipsis as l, isElementReady as m, isElementVisible as n, isElementInDocument as o, getSiblings as p, stringToNumberArray as q, randomString as r, setElementAttributes as s, stringToObject as t, isAlphaNumeric as u, isDefined as v, isString as w, isObject as x, isEmptyObject as y, hasValue as z };